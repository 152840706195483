import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./establishmentTrainingCourseDetails.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import LocationPicker from "../maps/LocationPicker";
import { DEFAULT_MAP_LOCATION } from "../../common/constants/mapLocation";
import "./establishmentTrainingUserDetails.scss";

class EstablishmentTrainingUserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.titleAndInfo = this.titleAndInfo.bind(this);
    this.updateUserDate = this.updateUserDate.bind(this);
  }

  handleLocationChange = (location) => {
    const { address, position } = location;
    return this.setState({ address, lat: position.lat, lng: position.lng });
  };

  updateUserDate = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  titleAndInfo = (title, info, name = "", placeholder, isEditable = true) => {
    return (
      <div className="info-wrapper">
        <Text
          Tag="div"
          textType="p4"
          className="training-course-details-text info-wrapper__title"
        >
          {title}
        </Text>

        <Text
          Tag="div"
          textType="p4"
          className="training-course-details-text"
          isBold
        >
          {info ? info : "لا يوجد"}
        </Text>
      </div>
    );
  };

  render() {
    const { email, address, mobile, lat, lng } = this.props.trainingSubject;

    const mapLocationSpecified = lat && lng;
    const establishmentMapLocation = {
      lat: lat,
      lng: lng,
    };
    return (
      <div className="registered-training-user-info">
        <div className=" registered-training-user-info__box">
          <div className="training-course-details-white-box ">
            <span className="training-box-top-title">
              <Text
                Tag="div"
                textType="p3"
                className="training-course-details-title training-box-top-title__name"
              >
                بيانات التسجيل في الدورة التدريبية
              </Text>
            </span>
            <div className="info-box ">
              <div className="info-box__row ">
                {this.titleAndInfo(
                  "رقم التواصل",
                  mobile,
                  "mobile",
                  "05xx-xxx-xxxx"
                )}

                {this.titleAndInfo(
                  " البريد الإلكتروني",
                  email,
                  "email",
                  "example@company.com"
                )}
              </div>
              {this.titleAndInfo("العنوان", address, "", null, false)}
              <div className=" establishmnet-profile-map info-box__map">
                <LocationPicker
                  loadingElement={<div>Loading...</div>}
                  containerElement={<div className={`gmap-wrap `} />}
                  mapElement={<div className="gmap-elem" />}
                  defaultPosition={
                    mapLocationSpecified
                      ? establishmentMapLocation
                      : DEFAULT_MAP_LOCATION
                  }
                  mapControlsEnabled={false}
                  onChange={this.handleLocationChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EstablishmentTrainingUserDetails.propTypes = {
  trainingSubject: PropTypes.object,
};
EstablishmentTrainingUserDetails.defaultProps = {};

export default EstablishmentTrainingUserDetails;
