import React from "react";
import { Modal, ModalBody, Row } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import TextInput from "../TextInput/TextInput";
import LabeledDropDown from "../LabeledDropDown/LabeledDropDown";
import { arabicLettersWithNumbers } from "../../../utilities/helpers";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./collection-modal.scss";
export class CollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      dropDownValue: null,
      statusDropDownValue: null,
      searchValue: "",
      filteredCollection: null,
      // totalBlocked: 0,
    };

    this.onButtonClick = this.onButtonClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.searchTerm = this.searchTerm.bind(this);
    // this.searchTag = this.searchTag.bind(this);
    this.searchStatus = this.searchStatus.bind(this);
    this.getSearchCollection = this.getSearchCollection.bind(this);
    // this.getTotalBlocked = this.getTotalBlocked.bind(this);
  }

  onButtonClick(selected) {
    localStorage.setItem("selected", JSON.stringify(selected));
    this.props.onConfirm(JSON.parse(localStorage.getItem("selected")));
  }

  isSelected(item) {
    const { establishmentProfile } = this.props;
    if (Object.keys(establishmentProfile).length !== 0) {
      return establishmentProfile.id === item.id;
    } else return false;
  }
  updateCollection() {
    const { collection } = this.props;
    this.setState({ filteredCollection: collection });
  }
  // componentDidMount() {
  //   this.getTotalBlocked();
  // }

  componentDidUpdate(PrevProps) {
    const { collection, isLoading } = this.props;
    if (!isLoading && PrevProps.collection !== collection) {
      this.updateCollection();
      // this.getTotalBlocked();
    }
  }

  // getTotalBlocked() {
  //   const { collection } = this.props;
  //   const blockedEstablishments = collection.filter((establishment) => {
  //     return establishment.is_blocked === true;
  //   });
  //   this.setState({ totalBlocked: blockedEstablishments.length });
  // }

  getSearchCollection(searchTerm = "", type = "") {
    const { searchValue, dropDownValue, statusDropDownValue } = this.state;
    const { collection } = this.props;

    let tempSearchValue = type === "search" ? searchTerm : searchValue;
    // let tempDropDownValue =
    //   type === "blocked"
    //     ? searchTerm === 2
    //     : dropDownValue
    //     ? dropDownValue.value === 2
    //     : null;
    let tempStatusDropDownValue =
      type === "status"
        ? searchTerm
        : statusDropDownValue
        ? statusDropDownValue.value
        : null;
    let tempStatusDropDownTerm =
      tempStatusDropDownValue === 1
        ? "Partially Filled Empty"
        : tempStatusDropDownValue === 2
        ? "Completed"
        : null;

    if (type === "search") {
      return collection.filter((establishment) => {
        // if (dropDownValue && tempStatusDropDownValue) {
        //   return (
        //     (establishment.establishment_name.includes(
        //       tempSearchValue.trim()
        //     ) ||
        //       establishment.sequence_id.includes(tempSearchValue.trim()) ||
        //       establishment.labor_office_id.includes(tempSearchValue.trim())) &&
        //     establishment.is_blocked === tempDropDownValue &&
        //     tempStatusDropDownTerm.includes(
        //       establishment.assessments_status.trim()
        //     )
        //   );
        // } else if (dropDownValue) {
        //   return (
        //     (establishment.establishment_name.includes(
        //       tempSearchValue.trim()
        //     ) ||
        //       establishment.sequence_id.includes(tempSearchValue.trim()) ||
        //       establishment.labor_office_id.includes(tempSearchValue.trim())) &&
        //     establishment.is_blocked === tempDropDownValue
        //   );
        // } else
        if (tempStatusDropDownValue) {
          return (
            (establishment.establishment_name.includes(
              tempSearchValue.trim()
            ) ||
              establishment.sequence_id.includes(tempSearchValue.trim()) ||
              establishment.labor_office_id.includes(tempSearchValue.trim())) &&
            tempStatusDropDownTerm.includes(
              establishment.assessments_status.trim()
            )
          );
        } else {
          return (
            establishment.establishment_name.includes(tempSearchValue.trim()) ||
            establishment.sequence_id.includes(tempSearchValue.trim()) ||
            establishment.labor_office_id.includes(tempSearchValue.trim())
          );
        }
      });
    }
    // else if (type === "blocked") {
    //   return collection.filter((establishment) => {
    //     if (tempSearchValue.length > 0 && tempStatusDropDownValue) {
    //       return (
    //         (establishment.establishment_name.includes(
    //           tempSearchValue.trim()
    //         ) ||
    //           establishment.sequence_id.includes(tempSearchValue.trim()) ||
    //           establishment.labor_office_id.includes(tempSearchValue.trim())) &&
    //         establishment.is_blocked === tempDropDownValue &&
    //         tempStatusDropDownTerm.includes(
    //           establishment.assessments_status.trim()
    //         )
    //       );
    //     } else if (tempSearchValue.length > 0) {
    //       return (
    //         (establishment.establishment_name.includes(
    //           tempSearchValue.trim()
    //         ) ||
    //           establishment.sequence_id.includes(tempSearchValue.trim()) ||
    //           establishment.labor_office_id.includes(tempSearchValue.trim())) &&
    //         establishment.is_blocked === tempDropDownValue
    //       );
    //     } else if (tempStatusDropDownValue) {
    //       return (
    //         establishment.is_blocked === tempDropDownValue &&
    //         tempStatusDropDownTerm.includes(
    //           establishment.assessments_status.trim()
    //         )
    //       );
    //     } else {
    //       return establishment.is_blocked === tempDropDownValue;
    //     }
    //   });
    // }
    else if (type === "status") {
      return collection.filter((establishment) => {
        // if (dropDownValue && tempSearchValue.length > 0) {
        //   return (
        //     (establishment.establishment_name.includes(
        //       tempSearchValue.trim()
        //     ) ||
        //       establishment.sequence_id.includes(tempSearchValue.trim()) ||
        //       establishment.labor_office_id.includes(tempSearchValue.trim())) &&
        //     establishment.is_blocked === tempDropDownValue &&
        //     tempStatusDropDownTerm.includes(
        //       establishment.assessments_status.trim()
        //     )
        //   );
        // }
        //  else if (dropDownValue) {
        //   return (
        //     tempStatusDropDownTerm.includes(establishment.assessments_status.trim()) &&
        //     establishment.is_blocked === tempDropDownValue
        //     );
        // }
        // else
        if (tempSearchValue.length > 0) {
          return (
            (establishment.establishment_name.includes(
              tempSearchValue.trim()
            ) ||
              establishment.sequence_id.includes(tempSearchValue.trim()) ||
              establishment.labor_office_id.includes(tempSearchValue.trim())) &&
            tempStatusDropDownTerm.includes(
              establishment.assessments_status.trim()
            )
          );
        } else {
          return tempStatusDropDownTerm.includes(
            establishment.assessments_status.trim()
          );
        }
      });
    }
  }

  searchTerm(value) {
    const { collection } = this.props;
    const searchTerm = arabicLettersWithNumbers(value);
    let newCollection;

    if (searchTerm.length > 0) {
      newCollection = this.getSearchCollection(searchTerm, "search");
      this.setState({
        searchValue: searchTerm,
        filteredCollection: newCollection,
      });
    } else {
      newCollection = this.getSearchCollection(searchTerm, "search");
      this.setState({
        searchValue: "",
        filteredCollection: newCollection,
      });
    }
  }

  // searchTag(searchTerm) {
  //   const { collection } = this.props;
  //   let newCollection;

  //   if (searchTerm) {
  //     newCollection = this.getSearchCollection(searchTerm.value, "blocked");
  //     this.setState({
  //       dropDownValue: searchTerm,
  //       filteredCollection: newCollection,
  //     });
  //   } else {
  //     newCollection = collection;
  //     this.setState({
  //       dropDownValue: null,
  //       filteredCollection: newCollection,
  //     });
  //   }
  // }

  searchStatus(searchTerm) {
    const { collection } = this.props;
    let newCollection;

    if (searchTerm) {
      newCollection = this.getSearchCollection(searchTerm.value, "status");
      this.setState({
        statusDropDownValue: searchTerm,
        filteredCollection: newCollection,
      });
    } else {
      newCollection = collection;
      this.setState({
        statusDropDownValue: null,
        filteredCollection: newCollection,
      });
    }
  }

  render() {
    const { isOpen, collection, children, onClose, isLoading, isDisableClose } =
      this.props;
    const {
      filteredCollection,
      searchValue,
      dropDownValue,
      statusDropDownValue,
    } = this.state;
    const isSearching = searchValue.length > 0 || statusDropDownValue;
    // || dropDownValue
    const collectionData = isSearching ? filteredCollection : collection;
    // const establishmentText = this.state.totalBlocked > 1 ? "منشآة" : "منشأة";
    return (
      <Modal
        isOpen={isOpen}
        className="window-width collection-modal"
        contentClassName="collection-modal-content"
        backdropClassName="collection-modal-backdrop"
      >
        <div className="modal-header  collection-modal-header">
          <span className="modal-header-button">
            <button
              onClick={onClose}
              className={`close ${isDisableClose ? "disabled" : ""}`}
              aria-label="close"
              disabled={isDisableClose}
            >
              <span className="icon" aria-hidden="true">
                ×
              </span>
            </button>
          </span>
          <span className="collection-modal-header-text">
            <Text
              Tag="div"
              textType="h5"
              isBold
              className="collection-modal-header-text__title"
            >
              قائمة المنشآت
            </Text>
            <Text
              Tag="div"
              textType="p3"
              className="collection-modal-header-text__subtitle"
            >
              الرجاء اختيار المنشأة التي ترغب في عرض معلوماتها
            </Text>
          </span>
        </div>

        {/* <div className=" collection-modal-header-alert-wrapper">
          <div className=" collection-modal-header-alert ">
            <div className="image-text-wrapper">
              <img
                src={"img/alert_icon.svg"}
                alt="alert"
                className="alert-image"
              />
              <Text Tag="div" textType="p5" className="blocked-text">
                لديك {this.state.totalBlocked} {establishmentText} موقوفة
                {this.state.totalBlocked > 0 &&
                  "، بسبب عدم إكمال التقييم الذاتي"}
              </Text>
            </div>
          </div>
        </div> */}

        <div className=" collection-modal-header-filters-wrapper">
          <div className=" collection-modal-header-filters ">
            <TextInput
              type="text"
              onChange={(e) => {
                this.searchTerm(e.target.value);
              }}
              placeholder="بحث"
              value={this.state.searchValue}
              name="بحث"
              inputStyle={{ width: "80%" }}
              customWrapper={"text-input-wrapper"}
              icon={"img/search.svg"}
              disabled={isLoading || collection.length <= 0}
            />
            {/* <div className="drop-down-filter">
              <LabeledDropDown
                placeholder="حالة المنشأة"
                name="حالة المنشأة"
                isClearable={false}
                value={this.state.dropDownValue}
                onChange={(item) => {
                  this.searchTag(item);
                }}
                options={[
                  {
                    label: "نشطة",
                    value: 1,
                  },
                  {
                    label: "موقوفة",
                    value: 2,
                  },
                ]}
                isDisabled={isLoading || collection.length <= 0}
                dropDownWrapperStyle={"drop-down-filter__wrapper-style"}
              />
            </div> */}
            <div className="drop-down-filter">
              <LabeledDropDown
                placeholder="حالة التقييم"
                name="حالة التقييم"
                isClearable={false}
                value={this.state.statusDropDownValue}
                onChange={(item) => {
                  this.searchStatus(item);
                }}
                options={[
                  {
                    label: "غير مكتمل",
                    value: 1,
                  },
                  {
                    label: "مكتمل",
                    value: 2,
                  },
                ]}
                isDisabled={isLoading || collection.length <= 0}
                dropDownWrapperStyle={"drop-down-filter__wrapper-style"}
              />
            </div>
          </div>
        </div>
        <ModalBody className="collection-modal-body">
          <div className="collection-modal-body-container">
            {isLoading && (
              <div className="loader-wrapper">
                <Loader />
              </div>
            )}

            {!isLoading && collectionData && collectionData.length > 0
              ? collectionData.map((item, index) => (
                  <Row
                    onClick={() => {
                      this.setState({ selected: item });
                      this.onButtonClick(item);
                    }}
                    id={`establishment-${index}`}
                    className={`collection-modal-item ${
                      this.isSelected(item)
                        ? //  && !item.is_blocked
                          "selected"
                        : ""
                    }
                 
                    `}
                    // ${
                    //   item.is_blocked && this.isSelected(item)
                    //     ? "blocked-selected"
                    //     : ""
                    // } ${item.is_blocked ? "blocked" : ""}
                    key={item.id}
                  >
                    {children(item)}
                  </Row>
                ))
              : !isLoading &&
                isSearching && (
                  <Text Tag="span" textType="h9">
                    لا توجد نتيجة
                  </Text>
                )}
          </div>
        </ModalBody>
        <div className="modal-footer collection-modal-footer" />
      </Modal>
    );
  }
}

CollectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  collection: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  isDisableClose: PropTypes.bool.isRequired,
  establishmentProfile: PropTypes.object,
};
const mapStateToProps = (state) => ({
  establishmentProfile: state.establishment.establishmentProfile,
});
export default connect(mapStateToProps)(CollectionModal);
