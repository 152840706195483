import jsPDF from "jspdf";
import {
  EffraBold,
  EffraRegular,
  MolArabicRegular,
  MolArabicBold,
} from "./fonts";
import { MhrsdBackgroundLogo, ImsMhrsdLogo } from "./images";

import { format } from "date-fns/esm";
import { parseISO } from "date-fns";
import { ANSWERS } from "./caliberAnswers";
import { convertRawContentToText } from "../../helpers/text-handler";

export const PdfReport = (
  result,
  profile,
  isAuditReport = false,
  isBasic = false
) => {
  const pdf = new jsPDF("p", "pt", "a4", true);
  pdf.setR2L(true);
  const grayColor = "#505050";
  const greenColor = "#215647";
  const lightGreenColor = "#15415A";

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  pdf.addFileToVFS("Effra-Bold.ttf", EffraBold);
  pdf.addFileToVFS("Effra-Regular.ttf", EffraRegular);
  pdf.addFont("Effra-Regular.ttf", "Effra", "normal");
  pdf.addFont("Effra-Bold.ttf", "Effra", "bold");

  pdf.addFileToVFS("MolArabic-Bold.ttf", MolArabicBold);
  pdf.addFileToVFS("MolArabic-Regular.ttf", MolArabicRegular);
  pdf.addFont("MolArabic-Regular.ttf", "MolArabic", "normal");
  pdf.addFont("MolArabic-Bold.ttf", "MolArabic", "bold");

  const logos = () => {
    pdf.addImage(
      ImsMhrsdLogo,
      "PNG",
      pageWidth - 180,
      30,
      160,
      33,
      "ImsMhrsdLogo",
      "FAST"
    );
    pdf.addImage(
      MhrsdBackgroundLogo,
      "PNG",
      55,
      230,
      480,
      498,
      "MhrsdBackgroundLogo",
      "FAST"
    );
  };
  const addNewPage = () => {
    pdf.addPage();
    logos();
  };

  const processArabicText = (arabicText, x, y, alignment = "right") => {
    const options = {
      isInputVisual: false,
      isOutputVisual: true,
      isInputRtl: true,
      isOutputRtl: true,
      align: alignment,
    };
    return pdf.text(pdf.processArabic(arabicText), x, y, options);
  };

  const replaceParentheses = (text) => {
    let newText = text
      .replace(/،/g, " ، ")
      .replace(/؟/g, " ؟ ")
      .replace(/\s\s/g, " ")
      .replace(/\s{3,}/g, "\n")
      .replace(/[ؐ-ًؕ-ٖٓ-ٟۖ-ٰٰۭ]/g, "")
      .replace(/(?<=[0-9])-/, ".")
      .replace(")", "(")
      .replace("(", ")");
    return newText;
    // return text.replace(")", "(").replace("(", ")");
  };

  const titleAndText = (dataArray, startX, startY) => {
    let beginX = startX;
    let beginY = startY;

    return dataArray.map((text, i) => {
      let longText = text;
      pdf.setFont("Effra", "normal");
      if (i === 1) {
        pdf.setFont("Effra", "bold");
        longText = pdf.splitTextToSize(text, 197);
      }
      processArabicText(longText, beginX, beginY, "right");
      beginY = beginY + 15;
    });
  };
  const handleRowTitle = (
    text,
    startY,
    previousItem,
    lastItem,
    nextItem,
    currentIndex
  ) => {
    let beginY = startY;
    let currentText = text;
    let previousLongText = pdf.splitTextToSize(previousItem, pageWidth - 160);
    let textDimensions = pdf.getTextDimensions(previousLongText);
    beginY = beginY + 25 + textDimensions.h;
    currentText = pdf.splitTextToSize(text, pageWidth - 160);
    const auditAnswerTitleIndex = 14;
    const auditCommentsTitleIndex = 16;
    // if (currentIndex === 12) {
    if (currentIndex === auditAnswerTitleIndex && isAuditReport) {
      //Make sure that the audit part does not get split
      let commentsHeight = 0;
      lastItem && lastItem.length > 0
        ? lastItem.map((comment) => {
            currentText = pdf.splitTextToSize(comment.comment, pageWidth - 160);
            const dimensions = pdf.getTextDimensions(currentText);
            commentsHeight = commentsHeight + dimensions.h;
          })
        : (commentsHeight = 60);
      const lengthOfCommentsArray =
        lastItem && lastItem.length ? lastItem.length : 0;
      commentsHeight = commentsHeight + lengthOfCommentsArray * 50;
      currentText = text;
      if (beginY + commentsHeight >= pageHeight - 40) {
        addNewPage();
        beginY = 130;
      }
    }
    // else if (currentIndex !== 14) {
    else if (currentIndex !== auditCommentsTitleIndex) {
      //make sure that the description for each title does not exceed page height
      let nextItemToString = String(nextItem);
      const nextLongText = pdf.splitTextToSize(
        nextItemToString,
        pageWidth - 160
      );
      textDimensions = pdf.getTextDimensions(nextLongText);
      const nextBeginY = beginY + 25 + textDimensions.h;
      if (nextBeginY >= pageHeight - 40) {
        addNewPage();
        beginY = 130;
      }
    } else if (beginY >= pageHeight - 40) {
      addNewPage();
      beginY = 130;
    }
    return { Y: beginY, currentText: currentText };
  };

  const tableRow = (dataArray, startX, startY) => {
    let beginX = startX;
    let beginY = startY;
    return dataArray.map((text, i) => {
      let longText = text;
      const isLastItem = i === dataArray.length - 1;
      pdf.setFont("Effra", "bold");
      pdf.setTextColor(greenColor);

      if (beginY >= pageHeight - 50) {
        addNewPage();
        beginY = 90;
      }

      if (i % 2 != 0 && (!isAuditReport || !isLastItem)) {
        pdf.setFont("Effra", "normal");
        pdf.setTextColor(grayColor);
        longText = pdf.splitTextToSize(text, pageWidth - 160);
      } else if (i % 2 === 0 && (!isAuditReport || !isLastItem)) {
        beginX = startX;
        const yAndCurrentText = handleRowTitle(
          text,
          beginY,
          dataArray[i - 1],
          dataArray[dataArray.length - 1],
          dataArray[i + 1],
          i
        );
        longText = yAndCurrentText.currentText;
        beginY = yAndCurrentText.Y;
      } else if (isLastItem && isAuditReport) {
        let commentsHeight = 40;
        let yValueBox = beginY - 60;
        pdf.setFont("Effra", "normal");
        pdf.setTextColor(grayColor);

        text && text.length > 0
          ? text.map((comment, index) => {
              const auditComment = isBasic
                ? comment.audit_user_comment
                : comment.comment;
              longText = pdf.splitTextToSize(
                replaceParentheses(auditComment),
                pageWidth - 160
              );
              const dimensions = pdf.getTextDimensions(longText);
              commentsHeight = commentsHeight + dimensions.h;

              processArabicText(longText, beginX, beginY, "right");

              if (index < text.length - 1) beginY = beginY + 50 + dimensions.h;
              else commentsHeight = commentsHeight - 15;
              if (yValueBox >= pageHeight - 40) {
                yValueBox = 130;
              }
            })
          : processArabicText("لا يوجد تعليقات", beginX, beginY, "right");
        //green audit background
        pdf.setDrawColor(lightGreenColor);
        pdf.setLineWidth(1);
        pdf.saveGraphicsState();
        pdf.setGState(new pdf.GState({ opacity: 0.1 }));
        const commentsLength = text ? text.length : 1;
        const commentsHeightBox = commentsLength * 50;
        const yAuditBox = yValueBox;
        const auditBoxHeight = commentsHeight + commentsHeightBox;
        pdf.roundedRect(
          30,
          yAuditBox,
          pageWidth - 98,
          auditBoxHeight,
          5,
          5,
          "F"
        );
        pdf.restoreGraphicsState();
        return { beginY: beginY, auditBoxHeight: auditBoxHeight };
      }
      if (isLastItem && !isAuditReport) {
        processArabicText(longText, beginX, beginY, "right");
        const textDimensions = pdf.getTextDimensions(longText);
        const nextBeginY = beginY + 25 + textDimensions.h;
        return { beginY: nextBeginY, auditBoxHeight: 0 };
      }
      if (!isAuditReport || !isLastItem) {
        processArabicText(longText, beginX, beginY, "right");
        beginX = beginX - 100;
      }
    });
  };
  logos();
  //report Title
  pdf.setFont("Effra", "bold");
  pdf.setFontSize(16);
  pdf.setTextColor(greenColor);
  const title = isAuditReport ? "تقرير نتيجة التدقيق" : "تقرير نتيجة التقييم";

  processArabicText(title, pageWidth / 2, 120, "center");

  //green rectangular border
  pdf.setLineWidth(1);
  pdf.setDrawColor(greenColor);
  pdf.roundedRect(50, 180, pageWidth - 90, 61, 10, 10, "S");

  const score = isAuditReport
    ? `${Math.round(result.auditing_score)}%`
    : `${Math.round(result.final_result)}%`;
  const scoreBefore =
    isAuditReport && isBasic && `${Math.round(result.final_result)}%`;
  const certificateName = isAuditReport ? result.certificate_name : "";
  // const penalty = `${result.max_penalty} ريال`;
  // const closingDays = `${result.closing_days} يوم`;

  pdf.setFontSize(12);

  //TEMPORARILY REMOVED
  // processArabicText(certificateName, pageWidth - 100, 225, "center");
  // processArabicText(score, pageWidth - 220, 225, "center");
  // processArabicText(penalty, pageWidth - 350, 225, "center");
  // processArabicText(closingDays, pageWidth - 470, 225, "center");

  //TEMPORARILY ADDED
  isAuditReport &&
    !isBasic &&
    processArabicText(certificateName, pageWidth / 3, 225, "center");
  isAuditReport &&
    isBasic &&
    processArabicText(scoreBefore, (pageWidth / 3) * 2, 225, "center");
  processArabicText(
    score,
    isAuditReport && !isBasic
      ? (pageWidth / 3) * 2
      : isBasic
      ? pageWidth - 350
      : pageWidth / 2,
    225,
    "center"
  );

  pdf.setFontSize(9);
  pdf.setFont("MolArabic", "normal");
  pdf.setTextColor(grayColor);

  //TEMPORARILY REMOVED
  // const xCertificate = pageWidth - 100;
  const xCertificate = pageWidth / 3;
  isAuditReport &&
    !isBasic &&
    processArabicText("الشهادة", xCertificate, 210, "center");

  //TEMPORARILY REMOVED
  // const xResult = pageWidth - 220;
  const xResult =
    isAuditReport && !isBasic
      ? (pageWidth / 3) * 2
      : isBasic
      ? pageWidth - 350
      : pageWidth / 2;
  const scoreTitle =
    isAuditReport && !isBasic ? "نتيجة التدقيق" : "نسبة الامتثال";
  const scoreTitleBefore = isAuditReport && isBasic && "نتيجة بعد التدقيق";
  processArabicText(
    isBasic ? scoreTitleBefore : scoreTitle,
    xResult,
    210,
    "center"
  );
  const xScoreAfter = (pageWidth / 3) * 2;
  const scoreTitleAfter = isAuditReport && isBasic && "نتيجة قبل التدقيق";
  isBasic &&
    isAuditReport &&
    processArabicText(scoreTitleAfter, xScoreAfter, 210, "center");

  //TEMPORARILY REMOVED
  // const xFines = pageWidth - 350;
  // processArabicText("مجموع الغرامات المتوقعة", xFines, 210, "center");

  // const xClosingDays = pageWidth - 470;
  // processArabicText("عدد أيام الإغلاق", xClosingDays, 210, "center");

  //establishment info
  pdf.setTextColor(33, 86, 71);
  pdf.setFontSize(12);
  pdf.setFont("Effra", "bold");
  processArabicText("بيانات المنشأة", pageWidth - 50, 300);

  pdf.setFontSize(9);

  const estName = ["اسم المنشأة", profile.establishment.establishment_name];
  const estNum = [
    "رقم المنشأة",
    `${profile.establishment.sequence_id}\\${profile.establishment.labor_office_id}`,
  ];

  const estSizeValue = replaceParentheses(profile.establishment.size_name);
  const estSize = ["حجم المنشأة", estSizeValue];

  const estActivity = [
    "نشاط المنشأة",
    profile.establishment.economic_activity_name,
  ];
  const estRange = [
    "نطاق المنشأة",
    replaceParentheses(profile.establishment.nitaqat_color),
  ];
  const estTaxNum =
    isAuditReport && isBasic
      ? [
          "الرقم الضريبي",
          profile.establishment.additional_information.tax_number,
        ]
      : ["الرقم الضريبي", profile.establishment.tax_number];

  let startY = 330;
  let startX1 = pageWidth - 60;
  let startX2 = pageWidth - (pageWidth / 3) * 1.3;
  let startX3 = pageWidth - (pageWidth / 3) * 2.2;

  let startY1 = 380;

  titleAndText(estName, startX1, startY);
  titleAndText(estNum, startX2, startY);
  titleAndText(estSize, startX3, startY);

  titleAndText(estActivity, startX1, startY1);
  titleAndText(estRange, startX2, startY1);
  titleAndText(estTaxNum, startX3, startY1);

  pdf.setFontSize(12);
  pdf.setFont("Effra", "bold");
  processArabicText("بيانات المفوض", pageWidth - 50, 450);

  pdf.setFontSize(9);

  const commissionerName = ["اسم المفوض", profile.request_created_by];
  const commissionerEmail = [
    "البريد الإلكتروني",
    profile.request_created_by_email
      ? profile.request_created_by_email
      : "لا يوجد",
  ];
  const commissionerNum = [
    "رقم الجوال",
    profile.request_created_by_mobile
      ? profile.request_created_by_mobile
      : "لا يوجد",
  ];

  let startY2 = 480;

  titleAndText(commissionerName, startX1, startY2);
  titleAndText(commissionerEmail, startX2, startY2);
  titleAndText(commissionerNum, startX3, startY2);

  pdf.setFontSize(12);
  pdf.setFont("Effra", "bold");
  processArabicText("تفاصيل الفترة التقييمية", pageWidth - 50, 550);

  pdf.setFontSize(9);

  const periodName = ["الفترة التقييمية", profile.period_name];
  const periodStartDate = [
    "تاريخ بداية الفترة التقييمية",
    profile.start_date
      ? `${format(parseISO(profile.start_date), "dd/MM/yyyy")}`
      : "",
  ];
  const periodEndDate = [
    "تاريخ نهاية الفترة التقييمية",
    profile.end_date
      ? `${format(parseISO(profile.end_date), "dd/MM/yyyy")}`
      : "",
  ];

  let startY3 = 580;

  titleAndText(periodName, startX1, startY3);
  titleAndText(periodStartDate, startX2, startY3);
  titleAndText(periodEndDate, startX3, startY3);

  if (isAuditReport) {
    pdf.setFontSize(12);
    pdf.setFont("Effra", "bold");
    processArabicText("تفاصيل المدققين", pageWidth - 50, 650);

    pdf.setFontSize(9);

    const auditors = profile.auditors ? profile.auditors : [];

    const auditCompanyName = ["شركة التدقيق", profile.committee_arabic_name];
    const supervisorName = ["المشرف", profile.supervisor_name];
    const auditorsNumber = ["عدد المدققين", auditors.length];
    let startY4 = 680;
    let startY5 = 730;

    titleAndText(auditorsNumber, startX3, startY4);
    titleAndText(auditCompanyName, startX1, startY4);
    titleAndText(supervisorName, startX2, startY4);

    auditors.forEach((auditor, i) => {
      if (i % 3 === 0) {
        if (i > 0) {
          startY5 += 30;
        }
        if (startY5 > pageHeight - 50) {
          addNewPage();
          startY5 = 120;
        }
        let auditor1Name;
        if (i === 0) {
          auditor1Name = ["قائمة المدققين", auditors[i].name];
        } else auditor1Name = ["", auditors[i].name];
        titleAndText(auditor1Name, startX1, startY5);
        if (auditors[i + 1]) {
          let auditor2Name = ["", auditors[i + 1].name];
          titleAndText(auditor2Name, startX2, startY5);
        }
        if (auditors[i + 2]) {
          let auditor3Name = ["", auditors[i + 2].name];
          titleAndText(auditor3Name, startX3, startY5);
        }
      }
    });
  }
  let finalYLocation;
  let finalAuditBoxHeight;

  result?.assessment?.standard_categories?.forEach(
    (standardCategory, standardCategoryIndex) => {
      if (standardCategory.id !== "qiwa_calibers") {
        standardCategory?.caliber_categories?.forEach(
          (caliberCategories, caliberCategoryIndex) => {
            addNewPage();
            //description box and text
            pdf.setFont("Effra", "bold");
            pdf.setFontSize(16);
            pdf.setTextColor(greenColor);
            processArabicText(
              standardCategory.name,
              pageWidth / 2,
              120,
              "center"
            );
            pdf.setFont("Effra", "normal");
            pdf.setFontSize(13);
            pdf.setTextColor(grayColor);
            processArabicText(
              caliberCategories.name,
              pageWidth / 2,
              140,
              "center"
            );
            const description =
              convertRawContentToText(caliberCategories.description) ||
              " لا يوجد شرح";
            const splitDescription = pdf.splitTextToSize(
              description,
              pageWidth
            );

            const dimensions = pdf.getTextDimensions(splitDescription);
            processArabicText(splitDescription, pageWidth - 80, 200);

            pdf.setLineWidth(1);
            pdf.setDrawColor(greenColor);
            pdf.roundedRect(
              30,
              170,
              pageWidth - 90,
              dimensions.h + 40,
              5,
              5,
              "S"
            );

            //description title
            pdf.setLineWidth(1);
            pdf.setFillColor(33, 86, 71);
            pdf.roundedRect(450, 160, 70, 18, 7, 7, "F");
            pdf.setTextColor(255, 255, 255);
            pdf.setFontSize(11);
            const caliberDescriptionTitle = "شرح المعيار";
            processArabicText(
              replaceParentheses(caliberDescriptionTitle),
              510,
              172
            );

            let yValueCaliber = 170 + dimensions.h + 100;

            const calibersArray = isAuditReport
              ? caliberCategories.labels[0].calibers
              : caliberCategories.calibers;
            calibersArray.forEach((value, index) => {
              const caliber = value;
              let newPage = false;
              if (
                yValueCaliber >= pageHeight - 40 ||
                yValueCaliber + 120 >= pageHeight - 40
              ) {
                addNewPage();
                newPage = true;
              }
              if (index !== 0) {
                yValueCaliber = newPage ? 130 : yValueCaliber + 120;

                pdf.setLineWidth(1.3);
                pdf.setDrawColor(greenColor);
                pdf.line(
                  80,
                  yValueCaliber - 40,
                  pageWidth - 80,
                  yValueCaliber - 40
                );
              }
              if (
                index === calibersArray.length - 1 &&
                caliberCategoryIndex ===
                  standardCategory.caliber_categories.length - 1 &&
                standardCategoryIndex ===
                  result.assessment.standard_categories.length - 1
              ) {
                finalYLocation = yValueCaliber;
              }
              const returnedValues = tableRow(
                [
                  "البند",
                  replaceParentheses(
                    isAuditReport ? caliber.name.String : caliber.name
                  ),
                  "الإجابة",
                  ...(caliber.is_answered !== null
                    ? [
                        `${
                          ANSWERS[caliber.new_answer]
                        }       ${replaceParentheses(
                          "(تم تحديث هذه الإجابة بعد إرجاع البند للمنشأة)"
                        )}`,
                      ]
                    : [ANSWERS[caliber.answer]]),

                  ...(caliber.is_answered !== null
                    ? ["الاجابة قبل التعديل "]
                    : []),

                  ...(caliber.is_answered !== null
                    ? [ANSWERS[caliber.answer]]
                    : []),

                  ...(caliber.is_answered !== null ? ["سبب الإرجاع"] : []),
                  ...(caliber.is_answered !== null
                    ? [caliber.caliber_return_reason_name]
                    : []),

                  ...(caliber.is_answered !== null &&
                  caliber.return_caliber_details?.length > 0
                    ? ["تفاصيل إضافية"]
                    : []),
                  ...(caliber.is_answered !== null &&
                  caliber.return_caliber_details?.length > 0
                    ? [caliber.return_caliber_details]
                    : []),
                  // "شرح البند",
                  // caliber.description
                  //   ? replaceParentheses(caliber.description)
                  //   : "لا يوجد شرح",
                  // "تصنيف المخالفة",
                  // caliber.violation_category.name,
                  "المرجعية النظامية",
                  replaceParentheses(caliber.corrections),
                  // "رقم المرجعية النظامية",
                  // caliber.reference
                  //   ? replaceParentheses(caliber.reference)
                  //   : "لا يوجد",
                  // "الغرامات",
                  // caliber.max_penalty_amount,
                  ...(isAuditReport ? ["إجابة المدقق"] : []),
                  ...(isAuditReport
                    ? [ANSWERS[caliber.audit_answer.auditor_answer]]
                    : []),
                  ...(isAuditReport ? ["تعليق المدقق"] : []),
                  ...(isBasic && isAuditReport
                    ? [caliber.audit_answer.all_audit_comments]
                    : isAuditReport
                    ? [caliber.audit_answer.comments]
                    : []),
                ],
                pageWidth - 80,
                yValueCaliber
              );
              finalYLocation = returnedValues[returnedValues.length - 1].beginY;
              finalAuditBoxHeight =
                returnedValues[returnedValues.length - 1].auditBoxHeight;
              yValueCaliber = returnedValues[returnedValues.length - 1].beginY;
            });
          }
        );
      }
    }
  );

  const note1 =
    "تم تقدير شدة المخالفة بناء على مقدار الغرامة ومدة الاغلاق المتوقعة.";
  const note2 =
    "ملاحظة: لا يعتبر هذا التقرير نهائيا ولا يتم اعتماده إلا بعد زيارة مفتش من وزارة الموارد البشرية والتنمية الاجتماعية ";
  const note3 =
    "أو المفوض بالتدقيق من قبل أحد الشركات المعتمدة في برنامج التقييم الذاتي واعتماد التقييم بشكل نهائي.";
  let note1Dimensions = pdf.getTextDimensions(note1);
  let note2Dimensions = pdf.getTextDimensions(note2);
  let note3Dimensions = pdf.getTextDimensions(note3);
  const notesHeight = note1Dimensions.h + note2Dimensions.h + note3Dimensions.h;

  const notesSectionYAxisEnd =
    finalYLocation + finalAuditBoxHeight + notesHeight + 20;
  let yLocation;
  if (notesSectionYAxisEnd >= pageHeight - 50) {
    addNewPage();
    yLocation = 90;
  } else {
    yLocation = isAuditReport
      ? finalYLocation + finalAuditBoxHeight
      : finalYLocation + 30;
  }
  pdf.setFont("Effra", "bold");
  pdf.setTextColor(greenColor);
  processArabicText("الملاحظات", pageWidth - 80, yLocation);
  pdf.setFont("Effra", "normal");
  pdf.setTextColor(grayColor);
  processArabicText([note1, note2, note3], pageWidth - 80, yLocation + 20);
  pdf.save(
    isAuditReport ? "تقرير نتيجة التدقيق .pdf" : "تقرير نتيجة التقييم .pdf"
  );
};
