import React from "react";
import { connect } from "react-redux";
import toastr from "toastr";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import * as unifiedActions from "../../../../actions/unifiedActions";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Papper from "../../../../components/Papper/Papper";
import "./unified-assessment-form.scss";
import LabeledCheckbox from "../../../../components/LabeledCheckBox/LabeledCheckbox";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";
import Button from "../../../../components/Button/Button";
import { Link } from "react-router-dom";

export class UnifiedAssessmentForm extends React.Component {
  constructor() {
    super();

    this.selectAll = this.selectAll.bind(this);
    this.selectEstablishment = this.selectEstablishment.bind(this);
    this.isAllSelected = this.isAllSelected.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.isSubmitVisible = this.isSubmitVisible.bind(this);
    this.getActiveEstablishment = this.getActiveEstablishment.bind(this);
  }

  onSubmit() {
    const {
      establishmentId,
      saveSelectedEstablishments,
      changePage,
      selected,
      periodId,
      assessmentId,
      periodAssessmentId,
      assessmentVersion,
      isBranchesSpecialTrack,
    } = this.props;

    if (selected && selected.length && !isBranchesSpecialTrack) {
      saveSelectedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion,
        selected,
        periodAssessmentId
      )
        .then(() => {
          changePage(2);
        })
        .catch(() => {
          toastr.error("حدث خطأ ما");
        });
    } else if (selected && selected.length && isBranchesSpecialTrack) {
      changePage(2);
    }
  }

  getActiveEstablishment() {
    const {
      unifiedEstablishments,
      isBranchesSpecialTrack,
      fastTrackEstablishments,
    } = this.props;

    if (isBranchesSpecialTrack) {
      return fastTrackEstablishments.filter(
        (establishment) =>
          !establishment.is_fast_track_branches &&
          establishment.is_submitted_basic_assessment
      );
    } else {
      return unifiedEstablishments.filter(
        (establishment) => establishment.status
      );
    }
  }

  selectAll(checked) {
    const { selectEstablishment, clearSelectedEstablishments } = this.props;

    if (checked) {
      const ids = this.getActiveEstablishment().map((item) => item.id);
      selectEstablishment(ids);
      return;
    }

    clearSelectedEstablishments();
  }

  selectEstablishment(checked, establishment) {
    const {
      selectEstablishment,
      deselectEstablishment,
      isBranchesSpecialTrack,
    } = this.props;

    if (!isBranchesSpecialTrack && !establishment.status) {
      return;
    }
    if (
      isBranchesSpecialTrack &&
      (!establishment.is_submitted_basic_assessment ||
        establishment.is_fast_track_branches)
    ) {
      return;
    }

    if (checked) {
      selectEstablishment(establishment.id);
      return;
    }

    deselectEstablishment(establishment.id);
  }

  isAllSelected() {
    const ids = this.getActiveEstablishment().map((item) => item.id);

    return isEqual([].concat(this.props.selected).sort(), ids.sort());
  }

  isSelected(id) {
    return this.props.selected.indexOf(id) !== -1;
  }

  isSubmitVisible() {
    const activeEstablishment = this.getActiveEstablishment();

    return !!(activeEstablishment && activeEstablishment.length && this.props.selected.length);
  }

  render() {
    const {
      unifiedEstablishments,
      isBranchesSpecialTrack,
      fastTrackEstablishments,
    } = this.props;
    const establishments = isBranchesSpecialTrack
      ? fastTrackEstablishments
      : unifiedEstablishments;

    if (!establishments?.length && isBranchesSpecialTrack) {
      return (
        <section className="unified-assessment">
          <Papper withBorder>
            <div className="ua-empty">
              <p className="ua-text ua-text--header">لا يوجد بيانات</p>
            </div>
          </Papper>
        </section>
      );
    }

    return (
      <section className="unified-assessment">
        {!isBranchesSpecialTrack ? (
          <div className="ua-unavailable">
            <div className="ua-unavailable-img">
              <img
                src={"img/EmptyState.svg"}
                alt="alert"
                className="EmptyState-image"
              />
            </div>
            <Text
              textType="h6"
              isBold
              className="ua-text ua-text-service-unavailable"
            >
              عذرًا، هذه الخدمة غير متاحة حاليًا ..
            </Text>

            <Link
              to="/establishmentBasicDurationsPage"
              id="assessment-list-redirect-link"
            >
              <Text
                textType="h8"
                isBold
                className="ua-text ua-text-redirect-link"
              >
                العودة للصفحة السابقة
              </Text>
            </Link>
          </div>
        ) : (
          <Papper withBorder>
            <Text textType="p3" isBold className="ua-text ua-text--header">
              تفاصيل الخدمة:
            </Text>
            <Text textType="p4" className="ua-text">
              خدمة تتيح لممثل المنشأة أداء تقيمها بشكل أسرع بحيث يتم توحيد
              الإجابات لجميع المنشآت ذات النشاط المتشابهه بناء على أول تقييم يتم
              إرساله، مع إمكانية إعادة التقييم المجاني خلال 14 يوم من تاريخ دفع
              الخدمة
            </Text>
            <Text textType="p4" isBold className="ua-text ua-text--header">
              يرجى اختيار المنشآت التي ترغب بتوحيد تقييمها:
            </Text>
            <div className="ua-establishment">
              <ul className="ua-establishment-list">
                <li
                  className={`ua-establishment-list__item ${
                    this.isAllSelected()
                      ? "ua-establishment-list__item--active"
                      : ""
                  }`}
                >
                  <LabeledCheckbox
                    type="checkbox"
                    checked={this.isAllSelected()}
                    onChange={this.selectAll}
                    label="جميع المنشآت"
                    style={{ color: "#171725" }}
                    textType="p4"
                    id="all-establishments-checkbox"
                  />
                </li>
                {establishments.map((establishment) => (
                  <li
                    className={`ua-establishment-list__item ${
                      this.isSelected(establishment.id)
                        ? "ua-establishment-list__item--active"
                        : ""
                    } ${
                      !isBranchesSpecialTrack
                        ? establishment.status
                          ? ""
                          : "ua-establishment-list__item--disabled"
                        : ""
                    }
                  ${
                    isBranchesSpecialTrack
                      ? establishment.is_submitted_basic_assessment &&
                        !establishment.is_fast_track_branches
                        ? ""
                        : "ua-establishment-list__item--disabled"
                      : ""
                  }
                  `}
                    key={establishment.id}
                  >
                    <label className="ua-checkbox-label ua-checkbox-label--with-group">
                      <Checkbox
                        type="checkbox"
                        onChange={this.selectEstablishment}
                        data={establishment}
                        checked={this.isSelected(establishment.id)}
                        disabled={
                          isBranchesSpecialTrack
                            ? !establishment.is_submitted_basic_assessment ||
                              establishment.is_fast_track_branches
                            : !establishment.status
                        }
                        id={`${establishment.labor_office_id}-${establishment.sequence_id}-establishment-checkbox`}
                      />
                      <div className="ua-checkbox-label__group">
                        <Text
                          textType="p4"
                          className="ua-checkbox-label__group-header"
                        >
                          {`(${establishment.labor_office_id} - ${
                            establishment.sequence_id
                          }) ${establishment.name}${
                            !establishment.status && !isBranchesSpecialTrack
                              ? " - تم تقييمها مسبقاً"
                              : ""
                          }${
                            !establishment.is_submitted_basic_assessment &&
                            isBranchesSpecialTrack
                              ? " - لم يتم تقييم الفترة الأساسية"
                              : ""
                          } ${
                            establishment.is_submitted_basic_assessment &&
                            establishment.is_fast_track_branches &&
                            isBranchesSpecialTrack
                              ? " - لا يمكنها التقييم"
                              : ""
                          }`}
                        </Text>
                        <Text
                          textType="p5"
                          className="ua-checkbox-label__group-desc"
                        >
                          {`${establishment.economic_activity} - ${establishment.size_name}`}
                        </Text>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </Papper>
        )}
        {isBranchesSpecialTrack && (
          <footer className="ua-footer">
            <Button
              onClick={this.onSubmit}
              disabled={!this.isSubmitVisible()}
              text="التالي"
              id="payment-next-button"
            />
          </footer>
        )}
      </section>
    );
  }
}

UnifiedAssessmentForm.propTypes = {
  changePage: PropTypes.func.isRequired,
  periodId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  establishmentId: PropTypes.string.isRequired,
  assessmentVersion: PropTypes.string.isRequired,
  withoutResult: PropTypes.bool.isRequired,

  unifiedEstablishments: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  fetchUnifiedEstablishments: PropTypes.func.isRequired,
  saveSelectedEstablishments: PropTypes.func.isRequired,
  selectEstablishment: PropTypes.func.isRequired,
  deselectEstablishment: PropTypes.func.isRequired,
  clearSelectedEstablishments: PropTypes.func.isRequired,
  isBranchesSpecialTrack: PropTypes.bool.isRequired,
  fastTrackEstablishments: PropTypes.array,
};

UnifiedAssessmentForm.defaultProps = {};

export const mapStateToProps = (state) => ({
  unifiedEstablishments: state.unified.establishments,
  selected: state.unified.selected,
  isLoading: state.unified.isLoading,
  fastTrackEstablishments: state.FastTrackForBranches.establishments,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchUnifiedEstablishments: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchUnifiedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      )
    ),
  saveSelectedEstablishments: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion,
    selected,
    periodAssessmentId
  ) =>
    dispatch(
      unifiedActions.saveSelectedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion,
        selected,
        periodAssessmentId
      )
    ),
  selectEstablishment: (id) => dispatch(unifiedActions.selectEstablishment(id)),
  deselectEstablishment: (id) =>
    dispatch(unifiedActions.deselectEstablishment(id)),
  clearSelectedEstablishments: () =>
    dispatch(unifiedActions.clearSelectedEstablishments()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnifiedAssessmentForm);
