import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cloneDeep from "lodash/cloneDeep";
import * as establishmentAssessmentActions from "../../actions/establishmentAssessmentActions";
import { fetchEstablishmentSelfAssessmentResult } from "../../actions/establishmentSelfAssessmentResultActions";
import EstablishmentScoreList from "../establishmentassesmentpayment/EstablishmentScoreList";
import {
  DETAILEDREPORT,
  GRACE_PERIOD,
  STATUSES,
  DETAILED_REPORT_WITH_GRACE_PERIOD,
} from "../../common/constants/paymentStatuses";
import {
  fetchDuration,
  saveCaliber,
  setSAFormPeriod,
  updateCaliber,
  updateCaliberCategory,
  updateLegalAgreement,
  getSurvay,
} from "../../actions/SAformActions";
import {
  loadPaymentServices,
  submitPaymentWithCorrection,
} from "../../actions/establishmentProfileActions";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import EstablishmentCaliberCategoryCard from "./EstablishmentCaliberCategoryCard";
import Page404 from "../../views/Pages/Page404/Page404";
import {
  ANSWER_INVALID,
  ANSWER_NO,
  ANSWER_NOT_APPLICABLE,
  ANSWER_YES,
} from "../../common/constants/caliberAnswers";

import {
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_FAST_TRACK,
} from "../../common/constants/assessmentStatuses";

import toastr from "toastr";
import "./index.css";

import checkMark from "../../../public/img/checkMarkWhite.svg";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SmallStepper from "./SmallStepper";
import LabeledCheckbox from "../LabeledCheckBox/LabeledCheckbox";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import "./manageEstablishmentAssessmentPage.scss";
import Lottie from "react-lottie";
import * as animationLoader from "../../../public/animation/loading.json";
import { replaceArabicNumbersToEnglish } from "../../../utilities/helpers";
import PopUp from "../../views/Components/PopUp/PopUp";
import { QiwaCaliberCard } from "../../../../frontend-audit/src/components/audit_form/QiwaCaliberCard";

const { object, array } = PropTypes;

export const isCaliberValid = (caliber, caliberAttachments) => {
  const isQiwaIntegrationNotCompliant = caliber.integrationServiceValue !== "";
  const isNotQiwaIntegration = caliber.integrationServiceValue === "";
  const isNoteVisibleNo = caliber.is_visible_establishment_comment_no;
  const isNoteVisibleNotApplicable =
    caliber.is_visible_establishment_comment_not_applicable;
  const isNumberVisibleNo =
    caliber.is_visible_establishment_violation_number_no;
  const isNumberVisibleNotApplicable =
    caliber.is_visible_establishment_violation_number_not_applicable;
  const isAnswered = caliber.is_answered;
  const isAnswerYes = caliber.answer.trim() === ANSWER_YES;
  const isAnswerNo = caliber.answer.trim() === ANSWER_NO;
  const isAnswerNotApplicable = caliber.answer.trim() === ANSWER_NOT_APPLICABLE;
  const isEmpty =
    caliber.violation_number === null || caliber.violation_number === "";
  // invalid if no value or default value
  const caliberAnswerValid =
    caliber.answer && caliber.answer.trim().length > 0 &&
    caliber.answer.trim() !== ANSWER_INVALID;

  //invalid if caliber note is empty
  const caliberNoteValid = caliber?.note?.trim().length > 0;

  const isValidNumber = new RegExp("^[\u0660-\u06690-9]+$");

  // count invalid attachments as mandatory attachments withount an answer (file)
  const caliberAttachmentsValid =
    !(caliber.answer?.trim() === ANSWER_YES) ||
    (caliberAttachments?.filter &&
      caliberAttachments.filter(
        (att) => att.mandatory && !att.submission_attachment_id?.Valid
      ).length === 0);

  if (!caliberAnswerValid && caliberAttachmentsValid) {
    return {
      allInvalid: null,
      answerInvalid:
        "لم يتم الإجابة على هذا السؤال، يرجى اختيار الجواب المناسب",
      attachmentInvalid: null,
      noteInvalid: null,
      numberInvalid: null,
    };
  } else if (caliberAnswerValid && !caliberAttachmentsValid) {
    return {
      allInvalid: null,
      answerInvalid: null,
      attachmentInvalid: "يرجى إرفاق الملفات المطلوبة",
      noteInvalid: null,
      numberInvalid: null,
    };
  } else if (
    caliberAnswerValid &&
    !caliberNoteValid &&
    (!isValidNumber.test(caliber.violation_number) || isEmpty) &&
    ((isNoteVisibleNo && isNumberVisibleNo && isAnswerNo) ||
      (isNoteVisibleNotApplicable &&
        isNumberVisibleNotApplicable &&
        isAnswerNotApplicable)) &&
    !isAnswerYes
  ) {
    return {
      allInvalid: null,
      answerInvalid: null,
      attachmentInvalid: null,
      noteInvalid: "يرجى إدخال مبررات عدم الالتزام",
      numberInvalid: "يرجى ادخال عدد المخالفين",
    };
  } else if (
    caliberAnswerValid &&
    !caliberNoteValid &&
    ((isNoteVisibleNo && isAnswerNo) ||
      (isNoteVisibleNotApplicable && isAnswerNotApplicable)) &&
    !isAnswerYes &&
    (isQiwaIntegrationNotCompliant || isNotQiwaIntegration)
  ) {
    return {
      allInvalid: null,
      answerInvalid: null,
      attachmentInvalid: null,
      noteInvalid: "يرجى إدخال مبررات عدم الالتزام",
      numberInvalid: null,
    };
  } else if (
    caliberAnswerValid &&
    isEmpty &&
    ((isNumberVisibleNo && isAnswerNo) ||
      (isNumberVisibleNotApplicable && isAnswerNotApplicable)) &&
    !isAnswerYes &&
    (isQiwaIntegrationNotCompliant || isNotQiwaIntegration)
  ) {
    return {
      allInvalid: null,
      answerInvalid: null,
      attachmentInvalid: null,
      noteInvalid: null,
      numberInvalid: "يرجى ادخال عدد المخالفين",
    };
  } else if (!caliberAnswerValid && !caliberAttachmentsValid) {
    return {
      allInvalid: "يرجى إكمال كافة متطلبات البند",
      answerInvalid: null,
      attachmentInvalid: null,
      numberInvalid: null,
    };
  } else
    return {
      allInvalid: null,
      answerInvalid: null,
      attachmentInvalid: null,
      numberInvalid: null,
    };
};

export class ManageEstablishmentAssessmentPage extends React.Component {
  constructor(props) {
    super(props);

    const { standardCategories = [] } = this.props.SAform;
    const tabs = [...standardCategories.map((sc) => sc.id), "submission_tab"];
    const { establishmentActiveAssessments } = this.props;
    this.state = {
      selfAssessmentPeriod: {
        validation: {
          invalidCalibers: [],
        },
      },
      page: 1,
      count: 0,
      pageCount: 0,
      isBack: false,
      isShowResult: false,
      activeIndex: 0,
      missingCalibers: [],
      missingQuestions: [],
      collapsed: true,
      collapsed2: true,
      tabs: tabs,
      activeTabId: tabs[0],
      isThereCalibres: false,
      isThereQuestions: false,
      attachmentCaliberId: null,
      caliberCategoryId: null,
      isSubmissionAgreementChecked: false,
      isLegalAgreementChecked: false,
      establishmentActiveAssessments: establishmentActiveAssessments
        ? establishmentActiveAssessments
        : [],
      openCaliberCategoryOrderNumber: 1,
      standardCategoryAnswers: [],
      standardCategoriesTotalCalibers: [],
      isGracePeriodChecked: false,
      isCaliberNotValid: false,
      isSubmissionSccess: false,
      isSubmissionLoading: false,
      isShowPopUp: false,
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.saveSelfAssessmentPeriod = this.saveSelfAssessmentPeriod.bind(this);
    this.finalSave = this.finalSave.bind(this);
    this.editCaliberAttachments = this.editCaliberAttachments.bind(this);
    this.closeModalCaliberAttachment =
      this.closeModalCaliberAttachment.bind(this);
    this.toggleOpenModalCaliberAttachments =
      this.toggleOpenModalCaliberAttachments.bind(this);
    this.showResult = this.showResult.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleOnChangeAnswer = this.handleOnChangeAnswer.bind(this);
    this.getIsAllCalibersAnswered = this.getIsAllCalibersAnswered.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { standardCategories: prevStandardCategories = [] } =
      this.props.SAform;
    const { standardCategories = [] } = nextProps.SAform;
    if (
      prevStandardCategories &&
      standardCategories &&
      prevStandardCategories.length !== standardCategories.length
    ) {
      const tabs = [
        ...(standardCategories.map((sc) => sc.id) || []),
        "submission_tab",
      ];

      this.setState({
        tabs: tabs || [],
        activeTabId: tabs && tabs.length > 0 ? tabs[0] : null,
      });
    }
  }

  componentDidMount() {
    const isReturned = this.props.location.state?.isReturned;
    const { actions, match } = this.props;
    let totalStandardCategoriesCalibers = [];
    if (!this.props.establishmentProfile.id) {
      this.props.history.push("/redirect");
    }
    actions.loadPaymentServices(this.props.establishmentProfile.id);
    actions
      .fetchDuration(
        match.params.id,
        match.params.assessment_version,
        match.params.periodAssessmentId,
        isReturned
      )
      .then((val) => {
        let totalCalibers;
        let standardCategoryAnswersTemp = [];
        const auditStatus = val?.audit_status;
        if (val?.assessment?.qiwa_calibers?.length > 0) {
          if (Array.isArray(val.assessment?.standard_categories)) {
            val.assessment?.standard_categories.unshift({
              id: "qiwa_calibers",
              caliber_categories: val?.assessment?.qiwa_calibers,
              secondary_id: "00000000-0000-0000-0000-000000000000",
              name: "البنود المؤتمتة",
              order: 0,
              completion_percentage: 100,
              is_deleted: false,
              is_optional: false,
              is_updated: false,
              weight: 0,
            });
          }
        } else {
          val.assessment.standard_categories = [
            {
              id: "qiwa_calibers",
              caliber_categories: val?.assessment?.qiwa_calibers,
              secondary_id: "00000000-0000-0000-0000-000000000000",
              name: "البنود المؤتمتة",
              order: 0,
              completion_percentage: 100,
              is_deleted: false,
              is_optional: false,
              is_updated: false,
              weight: 0,
            },
          ];
        }

        val?.assessment?.standard_categories?.map?.(
          (standardCategory, standardCategoryIndex) => {
            totalCalibers = 0;
            standardCategory?.caliber_categories?.map(
              (caliberCategory, caliberCategoryIndex) => {
                caliberCategory?.labels?.map?.((label) => {
                  totalCalibers = totalCalibers + label?.calibers?.length;
                  label?.calibers?.map?.((caliber, caliberIndex) => {
                    standardCategoryAnswersTemp?.push?.({
                      answer: caliber?.answer,
                      caliberIndex: caliberIndex,
                      caliberCategoryIndex: caliberCategoryIndex,
                      standardCategoryIndex: standardCategoryIndex,
                    });
                  });
                });
              }
            );
            totalStandardCategoriesCalibers?.push?.({
              totalCalibers: totalCalibers,
              standardCategoryIndex: standardCategoryIndex,
            });
          }
        );
        this.setState({
          standardCategoriesTotalCalibers: totalStandardCategoriesCalibers,
          standardCategoryAnswers: standardCategoryAnswersTemp,
          defaultCategoryAnswers: standardCategoryAnswersTemp,
          auditStatus,
          isReturned,
        });
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selfAssessmentPeriod &&
      this.props.selfAssessmentPeriod.id !== prevProps.selfAssessmentPeriod.id
    ) {
      this.setState({
        selfAssessmentPeriod: { ...this.props.selfAssessmentPeriod },
        isSubmissionAgreementChecked: false,
        isLegalAgreementChecked: false,
      });
    }
  }

  saveSelfAssessmentPeriod() {
    const { standardCategories, caliberCategories, calibers, attachments } =
      this.props.SAform;
    const updatedStandardCategories = standardCategories.map((sc) => ({
      ...cloneDeep(sc),
      caliber_categories: caliberCategories
        .filter((cc) => cc.standardCategoryId === sc.id)
        .map((cc) => ({
          ...cloneDeep(cc),
          labels: cc.labels.map((label) => ({
            ...cloneDeep(label),
            calibers: calibers
              .filter((c) => c.caliberCategoryId === cc.id)
              .map((c) => ({
                ...cloneDeep(c),
                violation_number:
                  c.violation_number && typeof c.violation_number === "string"
                    ? parseInt(
                        replaceArabicNumbersToEnglish(c.violation_number)
                      )
                    : c.violation_number
                    ? parseInt(c.violation_number)
                    : c.violation_number,
                attachments: attachments
                  .filter((ac) => ac.caliberId === c.id)
                  .map((ac) => cloneDeep(ac)),
              })),
          })),
        })),
    }));

    const [selfAssessmentPeriod] = this.state.establishmentActiveAssessments;
    const finshedSAPeriod = cloneDeep(selfAssessmentPeriod);
    return this.props.actions
      .savePeriodAssessment({
        ...finshedSAPeriod,
        assessment: {
          ...finshedSAPeriod.assessment,
          standard_categories: updatedStandardCategories.filter(
            (item) => item.id !== "qiwa_calibers"
          ),
        },
        isReturned: this.props.location.state?.isReturned,
        SAform: {
          ...this.props.SAform,
        },
      })
      .then(() => {
        this.setState({ isSubmissionSccess: true });
        this.setState({ isSubmissionLoading: false });
      })
      .catch((error) => {
        toastr.error("لم يتم الحفظ");
        this.setState({ isSubmissionLoading: false });
      });
  }

  nextPage() {
    const { tabs, activeTabId } = this.state;
    const currentTabIndex = tabs.indexOf(activeTabId);
    window.scrollTo(0, 0);

    if (tabs && currentTabIndex < tabs.length - 1) {
      const nextTabId = tabs[currentTabIndex + 1];
      this.setState({
        activeIndex: currentTabIndex + 1,
        openCaliberCategoryOrderNumber: 1,
      });
      this.setActiveTab(nextTabId);
    }

  }

  previousPage() {
    const { tabs, activeTabId } = this.state;
    const currentTabIndex = tabs.indexOf(activeTabId);
    window.scrollTo(0, 0);

    if (currentTabIndex > 0) {
      const prevTabId = tabs[currentTabIndex - 1];
      this.setState({
        activeIndex: currentTabIndex - 1,
        openCaliberCategoryOrderNumber: 1,
      });
      this.setActiveTab(prevTabId);
    }
  }

  validatePeriod() {
    const { updateCaliber, updateCaliberCategory } = this.props.actions;
    const { activeTabId } = this.state;
    const { calibers, caliberCategories, attachments } = this.props.SAform;
    const currentCaliberCategories = caliberCategories.filter(
      (caliberCategory) => caliberCategory.standardCategoryId === activeTabId
    );
    let currentCaliberCategoryIds = [];
    let invalidStandardCategoryCalibers = [];

    currentCaliberCategories.map((item) =>
      currentCaliberCategoryIds.push(item.id)
    );

    const validatedCalibers = calibers.map((caliber) => {
      const caliberAttachments = attachments.filter(
        (att) => att.caliberId === caliber.id
      );
      const isCurrentCaliber = currentCaliberCategoryIds.includes(
        caliber.caliberCategoryId
      );

      const {
        allInvalid,
        answerInvalid,
        attachmentInvalid,
        noteInvalid,
        numberInvalid,
      } = isCurrentCaliber
        ? isCaliberValid(caliber, caliberAttachments)
        : false;

      if (
        isCurrentCaliber &&
        (allInvalid ||
          answerInvalid ||
          attachmentInvalid ||
          noteInvalid ||
          numberInvalid)
      )
        invalidStandardCategoryCalibers.push(caliber);
      return {
        ...caliber,
        allInvalid,
        answerInvalid,
        attachmentInvalid,
        noteInvalid,
        numberInvalid,
        notValid:
          allInvalid ||
          answerInvalid ||
          attachmentInvalid ||
          noteInvalid ||
          numberInvalid
            ? true
            : false,
      };
    });

    validatedCalibers.forEach(updateCaliber);

    currentCaliberCategories.forEach((caliberCategory) => {
      const currentCategoryCalibers = validatedCalibers.filter(
        (caliber) => caliber.caliberCategoryId === caliberCategory.id
      );
      const currentCategoryInvalidCalibers = currentCategoryCalibers.filter(
        (caliber) => caliber.notValid
      );

      const caliberCategoryNotValid = currentCategoryInvalidCalibers && currentCategoryInvalidCalibers.length > 0;

      updateCaliberCategory({
        ...caliberCategory,
        notValid: caliberCategoryNotValid,
      });
    });

    const invalidCalibers = validatedCalibers.filter(
      (caliber) => caliber.notValid
    );

    const periodValid = invalidCalibers && invalidCalibers.length === 0;

    const standardCategoryValid = invalidStandardCategoryCalibers && invalidStandardCategoryCalibers.length === 0;

    const { selfAssessmentPeriod } = this.state;
    const updatedSelfAssessmentPeriod = {
      ...cloneDeep(selfAssessmentPeriod),
      isValid: periodValid,
    };

    this.setState((state, props) => ({
      selfAssessmentPeriod: updatedSelfAssessmentPeriod,
    }));

    return standardCategoryValid;
  }

  finalSave(event) {
    event.preventDefault();

    const periodValid = this.validatePeriod();

    if (!periodValid) {
      return;
    }
    const { calibers, assessmentVersion } = this.props.SAform;
    const {
      assessmentId,
      periodId,
      establishmentProfile,
      services,
      actions,
      history,
    } = this.props;
    const { isGracePeriodChecked } = this.state;
    this.setState({ isSubmissionLoading: true });
    const serviceIds = services
      .filter(
        (service) =>
          service.type === DETAILEDREPORT || service.type === GRACE_PERIOD
      )
      .map((service) => service.id);

    calibers.map((caliber) => {
      if (!caliber.is_optional) {
        saveCaliber(caliber, assessmentId, periodId);
      }
    });
    // this.saveSelfAssessmentPeriod().then(this.redirect);
    if (isGracePeriodChecked) {
      if (
        establishmentProfile.address_country &&
        establishmentProfile.address_district &&
        establishmentProfile.address_building_number &&
        establishmentProfile.address_postal_code &&
        establishmentProfile.address_street &&
        establishmentProfile.address_city
      ) {
        this.saveSelfAssessmentPeriod().then((val) =>
          actions
            .submitPaymentWithCorrection(
              establishmentProfile.id,
              serviceIds,
              periodId,
              assessmentId,
              assessmentVersion
            )
            .then((val) => {
              // toastr.success("تم طلب الدفع بنجاح");
              history.push(
                `/checkout/${val.hyperpay_bill.id}/${DETAILED_REPORT_WITH_GRACE_PERIOD}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`
              );
            })
            .catch((error) => {
              if (error == 424) {
                this.setState({
                  isShowPopUp: true,
                });
              } else if (error && error.details) {
                toastr.error(error.details);
              } else {
                toastr.error("حدث خطأ ما");
              }
            })
        );
      } else {
        this.setState({ isShowPopUp: true, isSubmissionLoading: false });
      }
    } else {
      this.saveSelfAssessmentPeriod().then(this.redirect);
    }
  }

  showResult(event) {
    event.preventDefault();
    const { legalAgreementChecked } = this.props.SAform;

    const periodValid = this.validatePeriod();

    if (!periodValid) {
      return;
    }
    const { calibers, assessmentVersion } = this.props.SAform;
    const { assessmentId, periodId, establishmentProfile } = this.props;

    calibers.map((caliber) => {
      if (!caliber.is_optional) {
        saveCaliber(caliber, assessmentId, periodId);
      }
    });
    this.setState({ isShowResult: true });
    this.props.actions.fetchEstablishmentSelfAssessmentResult(
      periodId,
      assessmentId,
      establishmentProfile.id,
      assessmentVersion
    );
  }

  redirect() {
    const { assessmentId, periodId, establishmentProfile, SAform } = this.props;
    const { assessmentVersion } = this.props.SAform;
    const { match } = this.props;
    toastr.success("تم الإرسال");

    this.props.history.push({
      //pathname: `/establishmentAssessmentResult/${periodId}/${assessmentId}/${establishmentProfile.id}/${assessmentVersion}`,
      isAssessmentSubmitted: true,
      assessmentId: assessmentId,
      periodId: periodId,
      assessmentVersion: assessmentVersion,
      profileId: establishmentProfile.id,
      isBasicAssessment: match.params.isBasicAssessment === "true",
      periodAssessmentId: SAform.PeriodAssessmentId,
    });
  }

  setActiveTab(tabId) {
    if (this.state.activeTabId !== tabId) {
      this.setState({ activeTabId: tabId });
    }
  }

  toggleOpenModalCaliberAttachments() {
    return this.setState({
      isOpenModalCaliberAttachments: !this.state.isOpenModalCaliberAttachments,
    });
  }

  editCaliberAttachments(caliber) {
    const { id } = caliber;

    this.setState({
      attachmentCaliberId: id,
    });
    return this.toggleOpenModalCaliberAttachments();
  }

  closeModalCaliberAttachment() {
    return this.toggleOpenModalCaliberAttachments();
  }

  onLegalAgreementChange = (val) => {
    this.setState({ isLegalAgreementChecked: val });
    this.props.actions.updateLegalAgreement(val);
  };
  onGracePeriodChange = (val) => {
    this.setState({ isGracePeriodChecked: val });
  };
  onSubmissionAgreementChange = (val) => {
    this.setState({ isSubmissionAgreementChecked: val });
  };

  handleOnChangeAnswer(caliberCategoryAnswers, standardCategoryIndex) {
    const { standardCategoryAnswers } = this.state;
    let caliberCategoryIndexes = [];
    let newArray = caliberCategoryAnswers.map((item) => {
      return {
        ...item,
        standardCategoryIndex: standardCategoryIndex,
      };
    });

    if (standardCategoryAnswers?.length > 0) {
      standardCategoryAnswers.map((item) => {
        caliberCategoryIndexes.push(item.caliberCategoryIndex);
      });
      caliberCategoryIndexes = [...new Set(caliberCategoryIndexes)];
    }
    if (newArray && newArray.length > 0) {
      const firstItem = newArray[0];

      if (
        caliberCategoryIndexes &&
        caliberCategoryIndexes.includes(firstItem.caliberCategoryIndex) &&
        standardCategoryAnswers
      ) {
        const newAnswers = standardCategoryAnswers.filter((item) => {
          // Ensure that item and caliberCategoryIndex are defined before accessing them
          return item && item.caliberCategoryIndex !== undefined && firstItem.caliberCategoryIndex !== item.caliberCategoryIndex;
        });

        this.setState({
          standardCategoryAnswers: [...newAnswers, ...newArray],
          isCaliberNotValid: false,
        });
      }
    } else
      this.setState({
        standardCategoryAnswers: [...standardCategoryAnswers, ...newArray],
        isCaliberNotValid: false,
      });
  }

  getIsAllCalibersAnswered(currentIndex) {
    const { standardCategoryAnswers, standardCategoriesTotalCalibers } =
      this.state;

    const validStandardCategoryAnswers = standardCategoryAnswers?.filter?.(
      (item) => item.answer !== "invalid"
    );

    const getTotalCalibersForActiveIndex =
      standardCategoriesTotalCalibers?.filter?.(
        (item) => item.standardCategoryIndex === currentIndex
      )[0];
    const answeredCalibersForActiveIndex =
      validStandardCategoryAnswers?.filter?.(
        (item) => item.standardCategoryIndex === currentIndex
      );
    const isAnsweredAllCalibers =
      getTotalCalibersForActiveIndex &&
      answeredCalibersForActiveIndex.length ===
        getTotalCalibersForActiveIndex.totalCalibers;

    return isAnsweredAllCalibers;
  }

  render() {
    const { assessmentVersion } = this.props.SAform;
    const {
      SAform,
      match,
      establishmentSelfAssessmentResult,
      establishmentProfile,
      isLoading,
      services,
      assessmentId,
      periodId,
      history,
    } = this.props;
    const {
      tabs,
      activeTabId,
      isShowResult,
      isLegalAgreementChecked,
      isSubmissionAgreementChecked,
      openCaliberCategoryOrderNumber,
      isGracePeriodChecked,
      isCaliberNotValid,
      isSubmissionLoading,
      isSubmissionSccess,
    } = this.state;
    const {
      standardCategories,
      caliberCategories,
      attachments,
      calibers,
      assessmentType,
    } = SAform;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationLoader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const serviceList = services.filter(
      (item) => item.type === DETAILEDREPORT || item.type === GRACE_PERIOD
    );

    if (isLoading) {
      return (
        <PageWrapper withBreadcrumbs>
          <div className="loader-div">
            <Lottie options={defaultOptions} height={160} width={160} />
            <p className="assessment-submission-loading-text">
              يرجى الانتظار..
            </p>
          </div>
        </PageWrapper>
      );
    } else if (SAform.periodId && match.params.id === SAform.periodId) {
      const isLastPage = tabs && tabs.indexOf(activeTabId) === (tabs.length - 1);
      const isFirstPage = tabs.indexOf(activeTabId) === 0;

      let invalidCaliberCategories;
      if (isLastPage) {
        invalidCaliberCategories = SAform.caliberCategories.filter(
          (caliberCategory) => caliberCategory.notValid
        );
      }

      standardCategories.forEach((sc) => {
        const standardCategoryCalibers = caliberCategories
          .filter((cc) => cc.standardCategoryId === sc.id)
          // .map(cc => calibers.filter(c => c.caliberCategoryId === cc.id)).flat(2);
          .map((cc) => calibers.filter((c) => c.caliberCategoryId === cc.id))
          .reduce((acc, val) => acc.concat(val), []);

        const standardCategoryCompletedCalibers =
          standardCategoryCalibers.filter((caliber) => {
            const caliberAttachments = attachments.filter(
              (att) => att.caliberId === caliber.id
            );
            return isCaliberValid(caliber, caliberAttachments);
          });


        if (standardCategoryCalibers && standardCategoryCalibers.length > 0) {
          sc.completion_percentage = parseInt(
            (standardCategoryCompletedCalibers.length / standardCategoryCalibers.length) * 100
          );
        } else {
          // Handle the case where standardCategoryCalibers is undefined or empty
          sc.completion_percentage = 0; // Set a default value or handle it as per your requirement
        }


      });
      return (
        <PageWrapper withBreadcrumbs>
          <div>
            <div className="big-stepper">
              {standardCategories && standardCategories.length !== 0 && tabs.length > 1 ? (
                <div className="stepper">
                  {standardCategories
                    .sort((a, b) => a.order - b.order)
                    .map((standardCategory, i) => (
                      <div
                        className={
                          this.state.activeIndex >= i
                            ? "stepper-item complete"
                            : this.state.activeIndex + 1 === i
                            ? "stepper-item "
                            : "stepper-item disabled"
                        }
                        key={standardCategory.id}
                        onClick={() => {
                          if (this.state.activeIndex > i) {
                            this.setActiveTab(standardCategory.id);
                            this.setState({
                              activeIndex: i,
                              openCaliberCategoryOrderNumber: 1,
                            });
                          } else if (this.state.activeIndex + 1 === i) {
                            const isValid = this.validatePeriod();
                            if (isValid) {
                              this.setActiveTab(standardCategory.id);
                              this.setState({
                                activeIndex: i,
                                openCaliberCategoryOrderNumber: 1,
                              });
                            }
                          }
                        }}
                      >
                        <Text Tag="div" textType="h6" className="stepper-text">
                          {this.getIsAllCalibersAnswered(i) ||
                          this.state.activeIndex > i ? (
                            <img
                              src={checkMark}
                              className="stepper-check-mark"
                            />
                          ) : null}
                          {standardCategory?.name}
                        </Text>
                      </div>
                    ))}

                  <div
                    className={
                      this.state.activeTabId === "submission_tab"
                        ? "stepper-item complete"
                        : this.state.activeIndex + 1 ===
                          standardCategories?.length
                        ? "stepper-item"
                        : "stepper-item disabled"
                    }
                    onClick={() => {
                      const isValid = this.validatePeriod();
                      if (
                        this.state.activeIndex + 1 ===
                        standardCategories?.length
                      )
                        if (isValid) {
                          this.setActiveTab("submission_tab");
                          this.setState({
                            activeIndex: "100",
                          });
                        }
                    }}
                  >
                    <Text Tag="div" textType="h6" className="stepper-text">
                      <img src={checkMark} className="stepper-check-mark" />
                      {assessmentType === ASSESSMENT_TYPE_BASIC
                        ? "الاضافات و الاقرارات"
                        : "تأكيد والإرسال"}
                    </Text>
                  </div>
                </div>
              ) : (
                <div className="stepper-single-item">
                  {tabs && tabs.length === 1 && (
                    <Text
                      Tag="div"
                      textType="h6"
                      className="stepper-single-text"
                    >
                      <img src={checkMark} className="stepper-check-mark" />
                      {standardCategories?.[0]?.name}
                    </Text>
                  )}
                </div>
              )}
            </div>
            <div className="small-stepper">
              <SmallStepper
                standardCategories={standardCategories}
                tabs={tabs}
                activeIndex={this.state.activeIndex}
                assessmentType={assessmentType}
              />
            </div>

            <TabContent
              activeTab={activeTabId}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {standardCategories && standardCategories.length !== 0 &&
                standardCategories.map(
                  (establishmentStandardCategory, index) => (
                    <TabPane
                      key={establishmentStandardCategory.id}
                      tabId={establishmentStandardCategory.id}
                      className="caliber-categories-tab-pane"
                    >
                      <Row>
                        <Col>
                          <Row className="caliber-categories-row">
                            <Col>
                              {caliberCategories
                                .filter(
                                  (cc) =>
                                    cc.standardCategoryId ===
                                    establishmentStandardCategory.id
                                )
                                .map((establishmentCaliberCategory, i) => (
                                  <EstablishmentCaliberCategoryCard
                                    key={establishmentCaliberCategory.id}
                                    isReturned={
                                      this.props.location.state?.isReturned
                                    }
                                    auditStatus={this.state.auditStatus}
                                    editCaliberAttachments={
                                      this.editCaliberAttachments
                                    }
                                    caliberCategory={
                                      establishmentCaliberCategory
                                    }
                                    orderNumber={i + 1}
                                    isOpenByDefault={i === 0}
                                    isOpen={
                                      i + 1 === openCaliberCategoryOrderNumber
                                    }
                                    onOpenCaliber={(
                                      openCaliberCategoryOrderNumber
                                    ) => {
                                      this.setState({
                                        openCaliberCategoryOrderNumber,
                                      });
                                    }}
                                    onChangeAnswer={(
                                      caliberAnswers,
                                      totalCalibersNumber
                                    ) =>
                                      this.handleOnChangeAnswer(
                                        caliberAnswers,
                                        index,
                                        totalCalibersNumber
                                      )
                                    }
                                  />
                                ))}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  )
                )}

              <TabPane tabId="qiwa_calibers">
                <QiwaCaliberCard items={this.props?.SAform?.qiwa_calibers} />
              </TabPane>

              <TabPane
                tabId="submission_tab"
                className="caliber-categories-tab-pane"
              >
                <Row>
                  <Col>
                    <Row className="caliber-categories-row">
                      <Col>
                        {isShowResult &&
                          assessmentType === ASSESSMENT_TYPE_FAST_TRACK && (
                            <Row
                              className="establishment-assessment-card"
                              style={{ paddingTop: "30px" }}
                            >
                              <Col>
                                <Row>
                                  <Col className="card-header-style">
                                    <strong
                                      className="underlined-green-wide"
                                      style={{ fontSize: "36px" }}
                                    >
                                      نتيجة التقييم المتوقعة
                                    </strong>
                                  </Col>
                                </Row>

                                <EstablishmentScoreList
                                  report={establishmentSelfAssessmentResult}
                                  establishmentProfile={establishmentProfile}
                                />
                              </Col>
                            </Row>
                          )}
                      </Col>
                    </Row>
                    <div className="caliber-categories-row">
                      <div>
                        {assessmentType === ASSESSMENT_TYPE_BASIC && (
                          <div
                            className="submission-text-card"
                            style={{ marginBottom: "50px" }}
                          >
                            <Text
                              textType="h7"
                              className="sa-submission-title"
                              isBold
                            >
                              الاضافات
                            </Text>
                            <Text
                              textType="p4"
                              className="establishment-profile-text font-16"
                            >
                              تتيح لك هذه الخدمتين الحصول على مهلة تصحيحية من
                              الزيارات التفتيشية التابعة لوزارة الموارد البشرية
                              والتنمية الاجتماعية لمدة شهرين، وإضافة التقرير
                              المفصل الى نتائج التقييم المتعلقة بمنشاتك التي
                              تتكون من نسبة الامتثال ومجموع الغرامات المتوقعة في
                              حال زيارة المفتش، مع الخطوات الإرشادية الواجب
                              عملها لرفع نسبة الامتثال وتفادي الغرامات المالية
                            </Text>

                            <div hidden={isGracePeriodChecked}>
                              <Text
                                textType="p4"
                                className="establishment-profile-text_color font-16"
                                isBold
                              >
                                تكلفة الخدمة شامل الضريبة المضافة (15%) :{" "}
                                {`${serviceList.reduce(
                                  (p, c) => p + c.amount * c.vat + c.amount,
                                  0
                                )} ريال سعودي`}
                              </Text>
                            </div>
                            <div
                              className={`${
                                isGracePeriodChecked
                                  ? "table-div-Open"
                                  : "table-div-closed"
                              } `}
                            >
                              <div
                                className={`service-table ${
                                  isGracePeriodChecked
                                    ? "table-show"
                                    : "table-hide"
                                }`}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "15px",
                                  marginLeft: "10px",
                                }}
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <Text
                                        Tag="th"
                                        textType="p4"
                                        isBold
                                        style={{
                                          textAlign: "center !important",
                                        }}
                                      >
                                        نوع الخدمة
                                      </Text>

                                      <Text Tag="th" textType="p4" isBold>
                                        المبلغ
                                      </Text>

                                      <Text Tag="th" textType="p4" isBold>
                                        ضريبة القيمة المضافة 15%
                                      </Text>

                                      <Text Tag="th" textType="p4" isBold>
                                        المبلغ الإجمالي
                                      </Text>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {serviceList && serviceList.length > 0 &&
                                      serviceList.map((service) => (
                                        <tr key={service.id}>
                                          <Text
                                            Tag="td"
                                            textType="p5"
                                            style={{
                                              textAlign: "center !important",
                                            }}
                                          >
                                            {STATUSES[service.type]}
                                          </Text>

                                          <Text
                                            Tag="td"
                                            textType="p5"
                                          >{`${service.amount} ريال`}</Text>

                                          <Text Tag="td" textType="p5">
                                            {`${
                                              service.amount * service.vat
                                            } ريال`}
                                          </Text>

                                          <Text Tag="td" textType="p5">
                                            {`${
                                              service.amount * service.vat +
                                              service.amount
                                            } ريال سعودي`}
                                          </Text>
                                        </tr>
                                      ))}

                                    {serviceList && serviceList.length > 1 && (
                                      <tr>
                                        <Text
                                          Tag="td"
                                          textType="p5"
                                          style={{
                                            textAlign: "center !important",
                                          }}
                                        >
                                          المجموع
                                        </Text>

                                        <td></td>
                                        <td></td>

                                        <Text Tag="td" textType="p5">
                                          {`${serviceList.reduce(
                                            (p, c) =>
                                              p + c.amount * c.vat + c.amount,
                                            0
                                          )} ريال سعودي`}
                                        </Text>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {/* <div
                              className={`
                                  ${
                                    isGracePeriodChecked
                                      ? "alert-div-Open "
                                      : "alert-div-closed "
                                  } `}
                            >
                              <div
                                className={`sadad-payment-warning ${
                                  isGracePeriodChecked
                                    ? "table-show"
                                    : "table-hide"
                                }`}
                              >
                                <div className="sadad-payment-warning__icon">
                                  <img
                                    src={"img/alert_yellow_Icon.svg"}
                                    alt="alert icon"
                                  />
                                </div>
                                <Text Tag="span" textType="p3">
                                  <Text Tag="div" textType="p3">
                                    يرجى سداد فواتيرك من خلال نظام سداد
                                    للمدفوعات باستخدام المعلومات التالية:
                                  </Text>
                                  <Text Tag="div" textType="p3">
                                    اسم الجهة: شركة تكامل لخدمات الأعمال
                                  </Text>
                                  رقم المفوتر: 199
                                </Text>
                              </div>
                            </div> */}

                            <div className="agreement-wrap">
                              <LabeledCheckbox
                                textType="p4"
                                type="checkbox"
                                checked={isGracePeriodChecked}
                                onChange={this.onGracePeriodChange}
                                label="أرغب في اضافة التقرير المفصل وشهادة المهلة التصحيحية لمدة شهرين"
                                id="agreement-1-checkbox"
                              />
                            </div>
                          </div>
                        )}
                        <div className="submission-text-card">
                          <div>
                            <Text
                              textType="h7"
                              className="sa-submission-title"
                              isBold
                            >
                              الاقرارات
                            </Text>
                          </div>
                          <div>
                            <div className="agreement-wrap">
                              <div
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                              >
                                <LabeledCheckbox
                                  textType="p4"
                                  type="checkbox"
                                  checked={isLegalAgreementChecked}
                                  onChange={this.onLegalAgreementChange}
                                  label=" أقر أن جميع البيانات التي تم إدخالها
                                        وإرفاقها صحيحة ومحدثة"
                                  id="agreement-1-checkbox"
                                />
                              </div>
                            </div>
                            <div className="agreement-wrap">
                              <LabeledCheckbox
                                type="checkbox"
                                checked={isSubmissionAgreementChecked}
                                onChange={this.onSubmissionAgreementChange}
                                textType="p4"
                                label="أوافق على أنه لا يمكن التعديل على النموذج بعد الارسال"
                                id="agreement-2-checkbox"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <div className=" assessment-footer">
              {isCaliberNotValid && (
                <div className=" assessment-error-message">
                  <Text
                    Tag="span"
                    textType="p5"
                    className={`${isCaliberNotValid ? "non-valid" : ""}`}
                  >
                    * يرجى الإجابة على جميع البنود وإكمال كافة المتطلبات
                  </Text>
                </div>
              )}

              <div className=" assessment-buttons">
                {!isFirstPage && (
                  <Button
                    className="btn-square"
                    style={{ marginLeft: "20px", minWidth: "118px" }}
                    onClick={this.previousPage}
                    text="السابق"
                    primary
                    id="previous-button"
                  />
                )}

                {isLastPage ? (
                  <div>
                    <Button
                      onClick={this.finalSave}
                      className=" btn-square"
                      disabled={
                        !isSubmissionAgreementChecked ||
                        !isLegalAgreementChecked
                      }
                      text="تأكيد وإرسال"
                      id="send-button"
                    />
                    {isSubmissionLoading ? (
                      <div className="assessment-submission-loading-background">
                        <div className="assessment-submission-loading">
                          <Lottie
                            options={defaultOptions}
                            height={160}
                            width={160}
                          />
                          <p className="assessment-submission-loading-text">
                            يرجى الانتظار..
                          </p>
                        </div>
                      </div>
                    ) : (
                      isSubmissionSccess &&
                      history.push({
                        pathname: `/assessmentSuccessSubmit/${periodId}/${assessmentId}/${establishmentProfile.id}/${assessmentVersion}`,
                        state: {
                          isOpen: !isGracePeriodChecked,
                        },
                      })
                    )}
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      const isValid = this.validatePeriod();
                      this.setState({
                        isCaliberNotValid: !isValid,
                      });
                      if (isValid) this.nextPage();
                    }}
                    className=" div-float btn-square"
                    text="التالي"
                    id="next-button"
                  />
                )}
              </div>
            </div>
          </div>
          <PopUp
            isOpen={this.state.isShowPopUp}
            title="إضافة عنوان المنشأة"
            body="لإتمام عملية الدفع يرجى إضافة العنوان الوطني للمنشأة ، و ذلك عن طريق الانتقال لصفحة ملف المنشأة."
            onClickCancel={() => this.setState({ isShowPopUp: false })}
            cancelButtonText="حسنًا"
          />
        </PageWrapper>
      );
    } else {
      return <Page404 />;
    }
  }
}

ManageEstablishmentAssessmentPage.propTypes = {
  actions: object.isRequired,
  history: object.isRequired,
  SAform: object,
  establishmentProfile: object,
  match: object,
  establishmentActiveAssessments: array,
  services: PropTypes.array.isRequired,
  getSurvay: PropTypes.func.isRequired,
};

ManageEstablishmentAssessmentPage.contextTypes = {
  router: PropTypes.object,
};

ManageEstablishmentAssessmentPage.defaultProps = {
  establishmentProfile: null,
  establishmentSelfAssessmentResult: null,
  establishmentSelfAssessmentResultFetchingError: null,
};

function getSelfAssessmentPeriodById(establishmentAssessments, id) {
  const selfAssessmentPeriod = establishmentAssessments.filter(
    (selfAssessmentPeriod) => selfAssessmentPeriod.id === id
  );

  if (selfAssessmentPeriod) return selfAssessmentPeriod[0]; //since filter returns an array, have to grab the first.
  return null;
}

function mapStateToProps(state, ownProps) {
  return {
    services: state.payment.reopen_assessment_services,
    isLoading: state.SAform.isLoading,
    establishmentActiveAssessments:
      state.establishment.establishmentActiveAssessments,
    assessmentId: state.SAform.assessmentId,
    periodId: state.SAform.periodId,
    assessmentVersion: state.SAform.assessmentVersion,
    isOpenReassessment: state.SAform.isOpenReassessment,
    establishmentProfile: state.establishment.establishmentProfile,
    establishmentSelfAssessmentResult:
      state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult,
    establishmentSelfAssessmentResultFetchingError:
      state.establishmentSelfAssessmentResult
        .establishmentSelfAssessmentResultFetchingError,
    SAform: cloneDeep(state.SAform),
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...establishmentAssessmentActions,
        setSAFormPeriod,
        updateCaliber,
        updateCaliberCategory,
        updateLegalAgreement,
        fetchDuration,
        fetchEstablishmentSelfAssessmentResult,
        loadPaymentServices,
        submitPaymentWithCorrection,
        getSurvay,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageEstablishmentAssessmentPage);
