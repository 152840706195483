import React, { useEffect } from "react";
import Content from "../../containers/Content/Content";
import GuideMainPage from "./components/GuideMainPage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./InteractiveGuidePage.scss";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import {
  standardCategories,
  caliberCategories,
  librarySections,
  guideVideos,
  calibers,
  basicCalibers,
  basicCaliberCategories,
} from "./static-content";

import GuideVideo from "./components/GuideVideo";

import CaliberSectionContent from "./components/CaliberSectionContent";
import CarouselSlider from "./components/CarouselSlider";
import ButtonWithIcon from "../reusableComponents/ButtonWithIcon";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./interactive-guide.css";
import {
  downloadAttachment,
  fetchInterActiveGuide,
} from "../../actions/interactiveguideActions";
import Loader from "../Loader/Loader";
import { Backdrop } from "@mui/material";

class InteractiveGuidePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      headerImageHeight: 0,
      currentSectionID: 0,
      currentCategoryName: "All",
      search: null,
      hideSearchIcon: false,
      caliberCategoryId: null,
      caliberCategoryTitle: null,
      caliberCategoryDescription: null,
      interActiveGuides: null,
      url: null,
      attachmentId: 0,
      isLoading: false,
      chosenTrack: 0,
      tracksType: true,
    };
  }
  componentDidMount() {
    this.getHeight();
    let elem = document.getElementById("website-container-margins");
    elem.style.margin = 0;
    window.scrollTo(0, 0);
    fetchInterActiveGuide().then((val) => {
      let interActiveGuidesFiles = val.interactive_guide_files;

      this.setState({ interActiveGuides: interActiveGuidesFiles });
    });
  }

  downloadFile = async (attachmentId) => {
    this.setState({ isLoading: true });
    await downloadAttachment(attachmentId).then((val) => {
      const url = window.URL.createObjectURL(val);
      this.setState({ url, attachmentId, isLoading: false });
      window.open(url, "_blank");
    });
  };

  searchSpace(event) {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  }
  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab)
      this.setState({
        activeTab: tab,
        currentSectionID: 0,
        caliberCategoryId: null,
        currentCategoryName: "All",
      });
    else {
      this.setState({
        caliberCategoryId: null,
      });
    }
  };
  getHeight = () => {
    const headerImageHeight =
      document.getElementById("headerContent").scrollHeight;
    this.setState({ headerImageHeight });
  };

  renderTab = (tabNumber, tabText) => {
    const { activeTab } = this.state;

    return (
      <Text
        Tag="li"
        textType="h8"
        key={tabNumber}
        className={
          activeTab === tabNumber ? " li-tab li-tab-active " : "li-tab"
        }
      >
        <a
          onClick={() => {
            this.toggle(tabNumber);
          }}
        >
          {tabText}
        </a>
      </Text>
    );
  };
  renderFilterButton = (title, id, category = null) => {
    const { currentSectionID, currentCategoryName } = this.state;

    return (
      <button
        key={id}
        className={
          (!category && currentSectionID === id) ||
          (category && currentCategoryName === category)
            ? "tab-filter-button active-filter-button "
            : "tab-filter-button"
        }
        onClick={() => {
          this.setState({
            currentSectionID: id,
            caliberCategoryId: null,
            currentCategoryName: category,
            tracksType: true,
          });
        }}
      >
        <Text Tag="span" textType="p5">
          {title}
        </Text>
      </button>
    );
  };
  renderFilters = (sections) => {
    const { currentSectionID, currentCategoryName } = this.state;

    return (
      <div className="filter-wrapper">
        <button
          key={0}
          className={
            currentSectionID === 0 || currentCategoryName === "All"
              ? "tab-filter-button active-filter-button "
              : "tab-filter-button"
          }
          onClick={() =>
            this.setState({
              currentSectionID: 0,
              caliberCategoryId: null,
              currentCategoryName: "All",
              tracksType: true,
            })
          }
        >
          <Text Tag="span" textType="p5">
            الكل
          </Text>
        </button>
        {sections.map((section) => {
          return this.renderFilterButton(
            section.label,
            section.id,
            section.category
          );
        })}
      </div>
    );
  };
  renderDocumentPreview = (label, id) => {
    const { search, url, attachmentId } = this.state;
    if (search == null || label.includes(search))
      return (
        <span key={`doc${label}`} className="library-attachment-wrapper">
          <a
            disabled={attachmentId !== id}
            onClick={() => this.downloadFile(id)}
            href={attachmentId !== id ? null : url}
            target="_blank"
          >
            <span className="library-attachment-inner-wrapper">
              <div className="library-attachment-icon-wrapper ">
                <img src={"img/document.svg"} alt="attachment" />
              </div>
              <Text
                textType="p5"
                className="attachment-label"
              >{`${label}`}</Text>
            </span>
          </a>
        </span>
      );
  };
  renderCaliber = (id) => {
    window.scrollTo(0, this.state.headerImageHeight);

    const {
      caliberCategoryId,
      caliberCategoryTitle,
      caliberCategoryDescription,
      chosenTrack,
    } = this.state;
    const chosenCalibers = chosenTrack === 0 ? basicCalibers : calibers;
    const caliberList = [];
    const chosenId = id ? id : caliberCategoryId;
    chosenCalibers
      .filter((caliber) => caliber.parentId.toString() === chosenId)
      .map((caliber) => {
        caliberList.push(caliber);
      });
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CaliberSectionContent
          calibers={caliberList}
          caliberCategoryTitle={caliberCategoryTitle}
          caliberCategoryDescription={caliberCategoryDescription}
        />
      </div>
    );
  };
  updateState = (id, label, description) => {
    this.setState({
      caliberCategoryId: `${id}`,
      caliberCategoryTitle: label,
      caliberCategoryDescription: description,
    });
  };

  renderCalibers = (label, id, description) => {
    const { search } = this.state;

    if (search == null || label.includes(search))
      return (
        <div className="interactive-guide-caliber-card " key={`caliber${id}`}>
          <div className="interactive-guide-caliber-card-content">
            <div className="interactive-guide-caliber-card-text ">
              <Text
                Tag="div"
                textType="h7"
                className="interactive-guide-caliber-title "
              >
                {label}
              </Text>
              <Text
                Tag="div"
                textType="p5"
                className="interactive-guide-caliber-description"
              >
                {description}
              </Text>
            </div>
            <div className="interactive-guide-caliber-card-button">
              <ButtonWithIcon
                text="عرض المزيد"
                align="left"
                onClick={(isClick) => {
                  this.setState({ tracksType: false });
                  isClick && this.updateState(id, label, description);
                }}
                textType="h10"
              />
              <img className="button_icon" src={"img/arrow_left.svg"} />
            </div>
          </div>
        </div>
      );
  };

  onHandleCaliberClick = (caliber) => {
    this.setState({
      caliberCategoryId: `${caliber.id}`,
      caliberCategoryTitle: caliber.label,
      caliberCategoryDescription: caliber.description,
    });
    this.renderCaliber();
  };

  render() {
    const {
      activeTab,
      headerImageHeight,
      currentSectionID,
      hideSearchIcon,
      search,
      caliberCategoryId,
      interActiveGuides,
      currentCategoryName,
      isLoading,
      chosenTrack,
      tracksType,
    } = this.state;

    const chosenCaliberCategories =
      chosenTrack === 0 ? basicCaliberCategories : caliberCategories;
    return (
      <Content history={this.props.history}>
        <div>
          <div
            style={{
              position: "static",
              minHeight: headerImageHeight,
            }}
          >
            <span className="header-wrapper">
              <div className="header-content " id="headerContent">
                <GuideMainPage />
              </div>

              <span
                className="tabs-container"
                style={{
                  top: headerImageHeight - 51,
                }}
              >
                <ul className="tabs-wrapper">
                  {this.renderTab("1", "المكتبة")}
                  {this.renderTab("2", "المعايير")}
                  {this.renderTab("3", "الفيديو")}
                </ul>
              </span>
            </span>
          </div>

          <div className="filter-items">
            {activeTab === "1" && this.renderFilters(librarySections)}
            {activeTab === "2" && this.renderFilters(standardCategories)}

            <div className="search-bar-wrapper">
              <div className="search-bar-with-icon">
                <input
                  type="text"
                  placeholder="بحث"
                  className="search-input"
                  onChange={(e) => this.searchSpace(e)}
                  onFocus={() => this.setState({ hideSearchIcon: true })}
                  onBlur={() => this.setState({ hideSearchIcon: false })}
                />
                {!hideSearchIcon && (
                  <div className="search-icon-wrapper">
                    <img
                      src={"img/searchIcon.svg"}
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="interactive-guide-tabs-wrapper">
            {activeTab === "2" && tracksType && (
              <div className="interactive-guide-tabs">
                <button
                  onClick={() => {
                    this.setState({ chosenTrack: 0 });
                  }}
                  className={`${
                    chosenTrack === 0
                      ? "interactive-guide-track-button-focus"
                      : ""
                  }`}
                >
                  <Text textType="h8" Tag="div">
                    معايير المسار الأساسي
                  </Text>
                </button>
                <button
                  onClick={() => {
                    this.setState({ chosenTrack: 1 });
                  }}
                  className={`${
                    chosenTrack === 1
                      ? "interactive-guide-track-button-focus"
                      : ""
                  }`}
                >
                  <Text textType="h8" Tag="div">
                    معايير المسار المميز
                  </Text>
                </button>
              </div>
            )}
          </div>
          <span>
            <TabContent
              activeTab={activeTab}
              className="docs-wrapper"
              style={{
                backgroundColor: "#f8f8f1",
              }}
            >
              <TabPane tabId="1" className="tab-pane">
                {isLoading && (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isLoading}
                  >
                    <Loader />
                  </Backdrop>
                )}

                <div className="attachments-container ">
                  {currentCategoryName === "All"
                    ? interActiveGuides?.map(({ name, id }) => {
                        return this.renderDocumentPreview(name, id);
                      })
                    : interActiveGuides?.map((item) => {
                        if (item.category == currentCategoryName)
                          return this.renderDocumentPreview(item.name, item.id);
                      })}
                </div>
              </TabPane>
              <TabPane tabId="2" className="tab-pane">
                {!caliberCategoryId ? (
                  <div className="attachments-container ">
                    {currentSectionID === 0
                      ? chosenCaliberCategories.map(
                          ({ label, id, description }) => {
                            return this.renderCalibers(label, id, description);
                          }
                        )
                      : chosenCaliberCategories
                          .filter((li) => li.parentId == currentSectionID)
                          .map(({ label, id, description }) => {
                            return this.renderCalibers(label, id, description);
                          })}
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    {this.renderCaliber()}
                    <CarouselSlider
                      caliberCategories={
                        currentSectionID === 0
                          ? chosenCaliberCategories
                          : chosenCaliberCategories.filter(
                              (li) => li.parentId == currentSectionID
                            )
                      }
                      onChooseCaliber={(caliber) =>
                        this.onHandleCaliberClick(caliber)
                      }
                    />
                  </div>
                )}
              </TabPane>
              <TabPane tabId="3" className="tab-pane">
                <div className="video-wrapper">
                  {guideVideos.map((video, index) => {
                    if (search == null || video.label.includes(search))
                      return (
                        <span
                          key={index}
                          className={
                            guideVideos && guideVideos.length > 0 &&
                            "guide-video-wrapper-margin "
                          }
                        >
                          <GuideVideo
                            video={video}
                            isPause={activeTab !== "3"}
                          />
                        </span>
                      );
                  })}
                </div>
              </TabPane>
            </TabContent>
          </span>
        </div>
      </Content>
    );
  }
}

export default InteractiveGuidePage;
