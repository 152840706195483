import React, { Component } from "react";
// import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../components/Button/Button";
import PropTypes from "prop-types";
import "./popUp.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import LabeledCheckbox from "../../../components/LabeledCheckBox/LabeledCheckbox";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

class PopUp extends Component {
  static defaultProps = {
    maxWidth: 500, // Default maxWidth
  };

  render() {
    const {
      onClickAction,
      title,
      body,
      onClickCancel,
      actionButtonText,
      cancelButtonText,
      isOpen,
      maxWidth,
      dismissButtonText,
      onClickDismiss,
      icon,
      bodyLink,
      checkboxLabel,
      isChecked,
      onCheckboxChange,
      exit,
      isCustomPopUpModel,
      closeIcon,
      image,
      onClickClose,
      boxStyle,
    } = this.props;

    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: this.props.maxWidth, // Use the maxWidth prop here
      width: "90%",
      bgcolor: "background.paper",
      borderRadius: "15px",
      boxShadow: 24,
      pt: 2,
      pb: 1,
      mr: 3,
    };

    return (
      <Modal
        open={isOpen}
        onClose={() => {}}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        // classes={`${isCustomPopUpModel} pop-up `}
        disableEscapeKeyDown
        className={`${isCustomPopUpModel} pop-up `}
      >
        <Box sx={{ ...style }} style={boxStyle}>
          <div className="modal-header">
            <div id="parent-modal-title" className="pop-up__header-row">
              {icon && (
                <div className="pop-up__header-icon">
                  <img src={icon} alt="alert icon" />
                </div>
              )}

              <Text
                Tag="div"
                textType="bold"
                fontSize={"font-20"}
                mobileFontSize={"font-18-mobile"}
                className="pop-up__header-title"
              >
                {title}
              </Text>
              {exit &&
                (closeIcon ? (
                  <div
                    className="close-icon-style"
                    onClick={onClickClose ? onClickClose : onClickCancel}
                  >
                    {closeIcon}
                  </div>
                ) : (
                  <div className="pop-up__header-close-icon">
                    <span
                      className="icon"
                      aria-hidden="true"
                      onClick={onClickClose ? onClickClose : onClickCancel}
                    >
                      ×
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div id="parent-modal-description" className="modal-body">
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-22"}
              mobileFontSize={"font-16-mobile"}
              mobileTextType="bold"
              className="pop-up-body-wrapper"
            >
              {body}
              {bodyLink}
            </Text>
            {checkboxLabel && (
              <div className="pop-up-body-wrapper">
                <LabeledCheckbox
                  type="checkbox"
                  checked={isChecked}
                  onChange={onCheckboxChange}
                  textType="p4"
                  label={checkboxLabel}
                />
              </div>
            )}
            {image && (
              <div className="pop-up-image-wrapper">
                {/* <img src={image} alt="image_icon" /> */}
                {image}
              </div>
            )}
            <div className="pop-up-buttons-wrapper">
              {actionButtonText && (
                <Button
                  customStyle={"pop-up-custom-button-responsive"}
                  onClick={() => onClickAction()}
                  text={actionButtonText}
                />
              )}
              {cancelButtonText && (
                <div className="pop-up-buttons-wrapper__second-button">
                  <Button
                    customStyle={"pop-up-custom-button-responsive"}
                    primary
                    text={cancelButtonText}
                    onClick={() => onClickCancel()}
                  />
                </div>
              )}
              {dismissButtonText && (
                <Button
                  customStyle={"pop-up-custom-button-responsive"}
                  secondary
                  text={dismissButtonText}
                  onClick={() => onClickDismiss()}
                />
              )}
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}
PopUp.propTypes = {
  onClickAction: PropTypes.func,
  onClickCancel: PropTypes.func,
  title: PropTypes.string,
  // body: PropTypes.string,
  actionButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isOpen: PropTypes.bool,
  dismissButtonText: PropTypes.string,
  onClickDismiss: PropTypes.func,
  icon: PropTypes.string,
  bodyLink: PropTypes.object,
  checkboxLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  onClickClose: PropTypes.func,
  closeIcon: PropTypes.elementType,
  image: PropTypes.elementType,
  isCustomPopUpModel: PropTypes.string,
};
export default PopUp;
