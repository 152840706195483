import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import LocationPickerLoader from "./LocationPickerLoader";
import { GoogleMapsKey } from "../../actions/establishmentProfileActions";

function LocationPicker(props) {
  const dispatch = useDispatch();
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState(null);

  useEffect(() => {
    async function fetchApiKey() {
      try {
        const apiKey = await dispatch(GoogleMapsKey());
        setGoogleMapsApiKey(apiKey);
      } catch (err) {
        console.error("Failed to load Google Maps API key:", err);
      }
    }
    fetchApiKey();
  }, [dispatch]);

  if (!googleMapsApiKey) {
    return <Loader />;
  }

  return <LocationPickerLoader googleMapsApiKey={googleMapsApiKey} {...props} />;
}

export default LocationPicker;
